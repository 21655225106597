import React, { useRef, memo, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { getStoreDetails } from '@/api/store-locator';
import {
  Input,
  useTheme,
  useBreakpointValue,
  InputRightElement,
  InputGroup,
  IconButton,
  FormLabel,
} from '@chakra-ui/react';
import { LOCALE } from '@/data/constants';
import { SearchAlertType } from '../store-locator/constants';
import { isPharmaprixBanner } from '@/utils';
import { validateStoreNumberSearch } from '../store-locator/store-locator-utils';
import type { ComboBoxProps } from '@react-types/combobox';
import { ComboBoxStateOptions, useComboBoxState } from '@react-stately/combobox';
import { useComboBox } from '@react-aria/combobox';
import { useViewportSize } from '@react-aria/utils';
import { MdSearch } from '@react-icons/all-files/md/MdSearch';
import { MdClear } from '@react-icons/all-files/md/MdClear';
import { Box } from '@ldfeplatform/componentlibrary.ui.atoms.box';
import { typographyBreakpoints } from '@ldfeplatform/componentlibrary.utils';
import { SearchDropdown } from '@ldfeplatform/componentlibrary.ui.molecules.search-dropdown';
import { Divider } from '@ldfeplatform/componentlibrary.ui.atoms.divider';
import { SearchMenuSkeleton } from '@ldfeplatform/componentlibrary.ui.skeletons.search-menu-skeleton';
import { Button } from '@ldfeplatform/componentlibrary.ui.atoms.button';
import { KEY_CODES } from '@ldfeplatform/componentlibrary.types';
import { ListBox } from './list-box';
import { HStack } from '@ldfeplatform/componentlibrary.ui.atoms.stack';
import { SearchBarProps } from '@ldfeplatform/componentlibrary.ui.atoms.form.search-bar';
import { trackSearchStore } from '@/utils/analytics/analytics-utils';
import { IGlobalContextFields } from '@/utils/analytics/analytics.interface';

export type SearchBarTypeaheadProps = Omit<ComboBoxProps<any>, 'children'> &
  ComboBoxStateOptions<any> &
  SearchBarProps & {
    /** component containing the options in the list */
    children?: (React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | null) | undefined;
    /* Minimum number of characters to trigger the search */
    minimumCharacter?: number;
    /** Loading state */
    isLoading?: boolean;
    /* Callback when the input is focused */
    openSearchDrawer?: () => void;
    /** Close search drawer */
    closeSearchDrawer?: () => void;
    /* Search drawer is open */
    searchDrawerIsOpen?: boolean;
    /** Cancel text */
    cancelText?: string;
    onSelectionChange?: any;
    globalContextFields: IGlobalContextFields;
    /** function returns the type of alert encountered during the search flow*/
    setSearchAlert: React.Dispatch<React.SetStateAction<string>>;
  };

const DEBOUNCE_INTERVAL = 200;

export const SearchBarTypeahead = memo((props: SearchBarTypeaheadProps) => {
  const {
    name,
    placeholderText,
    ariaLabelInput = 'search',
    onValueChange,
    onValueSubmit,
    onClearInput,
    openSearchDrawer,
    closeSearchDrawer,
    searchDrawerIsOpen,
    ariaLabelSearchButton = 'submit search',
    ariaLabelClear = 'clear',
    showClearButtonWhenInputHasValue,
    minimumCharacter = 3,
    isLoading = false,
    cancelText,
    globalContextFields,
    setSearchAlert,
    ...rest
  } = props;
  const {
    elevation: { low: elevationLow },
    palette: { pcxGreen },
    surface: { default: surfaceDefault },
    common: {
      common02Regular: { fontSize: cancelBtnFontSize },
    },
    formMediumInputText,
    formLargeInputText: { fontSize: formLargeInputTextFontSize },
    formSearchMediumHorizontal,
    formLargeVertical,
    formMediumIcon,
    formInterior,
    border: { default: borderDefault, hover: borderHover, emphasized: borderEmphasized },
    borderStyle: {
      default: { color, width, style },
    },
    text: { default: textDefault, deemphasized: textDeemphasized },
    formSearchBorderRadius,
    formSearchLargeHeight,
    element: { spacingSmall },
    localTokens: { searchBarIconRightPosition, formSearchInputFieldPaddingRight, searchBarQueryIconRightPosition },
  } = useTheme();

  const breakpointValue = useBreakpointValue(typographyBreakpoints, {
    ssr: false,
  });
  const isDesktop = breakpointValue === 'Desktop';

  // This is a special hook from react-aria that provides the viewport size
  // with the ios keyboard open.
  const { height: viewportHeight } = useViewportSize();

  const state: any = useComboBoxState({
    ...rest,
    allowsEmptyCollection: true,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef(document.createElement('div'));
  popoverRef.current?.setAttribute('aria-hidden', 'false');

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      label: ariaLabelInput,
      inputRef,
      popoverRef,
      listBoxRef,
    },
    state
  );

  const isGreaterThanMinCharLimit = state.inputValue.length >= minimumCharacter;

  useEffect(() => {
    if (!state.inputValue) {
      popoverRef.current = document.createElement('div');
      popoverRef.current?.setAttribute('aria-hidden', 'false');
    }
  }, [state.inputValue]);

  const shouldShowClearButton = showClearButtonWhenInputHasValue && state.inputValue;

  const debounceOnValueChange = useDebouncedCallback((val: string) => {
    onValueChange?.(val);
  }, DEBOUNCE_INTERVAL);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      inputProps.onChange?.(e);

      if (value?.length >= minimumCharacter) {
        debounceOnValueChange(value);
      }
    },
    [inputProps, state]
  );

  const clearBtnClickHandler = useCallback(() => {
    onClearInput?.();
    if (inputRef) {
      state.setInputValue('');
      state.close();
    }
  }, []);

  useEffect(() => {
    isGreaterThanMinCharLimit && state.setOpen(true);
  }, [state.inputValue]);

  const isMobileSearchTypeahead = !isDesktop && searchDrawerIsOpen;
  const isMobileSearchTypeaheadOpen = isMobileSearchTypeahead && state.isOpen;
  const [pressedTabKey, setPressedTabKey] = useState(false);
  const [isSearchTracked, setIsSearchTracked] = useState(false);

  const handleStoreNumberSearch = async (value: string) => {
    // Validate if store exists
    const storeId = value.replace(/[^0-9]/g, '');
    const lang = isPharmaprixBanner() ? LOCALE.FR : LOCALE.EN;

    try {
      await getStoreDetails(storeId, lang);
      window.location.href = `/store-locator/store/${storeId}`;
    } catch (error: any) {
      setSearchAlert(SearchAlertType.NonExistentStore);
    }
  };
  const handleSearchClick = useCallback(() => {
    if (validateStoreNumberSearch(state.inputValue)) {
      handleStoreNumberSearch(state.inputValue);
    } else if (onValueSubmit && !isSearchTracked) {
      onValueSubmit(state.inputValue);
      trackSearchStore(globalContextFields, 'manual-submit');
      setIsSearchTracked(true);
    }
  }, [onValueSubmit, state.inputValue, isSearchTracked]);

  const handleSearch = useCallback(() => {
    if (validateStoreNumberSearch(state.inputValue)) {
      handleStoreNumberSearch(state.inputValue);
    } else if (onValueSubmit && !isSearchTracked) {
      onValueSubmit(state.inputValue);
      trackSearchStore(globalContextFields, 'dropdown-submit');
      setIsSearchTracked(true);
    }
    if (isMobileSearchTypeahead) {
      closeSearchDrawer?.();
    }
  }, [onValueSubmit, state.inputValue, isSearchTracked, isMobileSearchTypeahead]);

  useEffect(() => {
    setIsSearchTracked(false);
  }, [state.inputValue]);

  useEffect(() => {
    if (state.selectedKey) {
      handleSearch();
    }
  }, [state.selectedKey]);

  const handleInputFieldFocus = useCallback(() => {
    !isDesktop && pressedTabKey && openSearchDrawer?.();
    setPressedTabKey(false);
  }, [isDesktop, pressedTabKey]);

  const handleInputFieldClick = useCallback(
    () => (isDesktop ? state.setOpen(true) : openSearchDrawer?.()),
    [isDesktop, state.setOpen]
  );

  const handleEnterKeyPress = (e: KeyboardEvent) => {
    const searchBtn = document.getElementById('search-typeahead-button');
    if (e.key === KEY_CODES.ENTER && searchBtn) {
      searchBtn.click();
    }
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.addEventListener('keydown', handleEnterKeyPress, true);
    }

    return () => {
      if (inputRef?.current) {
        inputRef.current.removeEventListener('keydown', handleEnterKeyPress);
      }
    };
  }, [inputRef?.current]);

  return (
    <Box data-testid="search-bar-container" position="relative">
      <HStack bgColor={surfaceDefault}>
        <InputGroup>
          <FormLabel
            {...labelProps}
            htmlFor={ariaLabelInput}
            position="absolute"
            boxSize={0}
            padding={0}
            overflow="hidden"
            whiteSpace="nowrap"
            border={0}
            style={{
              clip: 'rect(0, 0, 0, 0)',
            }}
          >
            {ariaLabelInput}
          </FormLabel>
          <Input
            data-testid="search-bar-input"
            {...inputProps}
            id={ariaLabelInput}
            name={name}
            ref={inputRef}
            onClick={handleInputFieldClick}
            onChange={handleOnChange}
            onFocus={handleInputFieldFocus}
            variant="outline"
            placeholder={placeholderText}
            backgroundColor={surfaceDefault}
            borderRadius={formSearchBorderRadius}
            borderColor={borderDefault}
            color={textDefault}
            _placeholder={{ color: textDeemphasized }}
            _hover={{ borderColor: borderHover }}
            _active={{
              borderColor: borderEmphasized,
            }}
            focusBorderColor="0"
            paddingLeft={formSearchMediumHorizontal}
            paddingRight={formSearchInputFieldPaddingRight}
            paddingY={formLargeVertical}
            height={formSearchLargeHeight}
            {...formMediumInputText}
            fontSize={formLargeInputTextFontSize}
          />
          <>
            <InputRightElement top="50%" transform="translateY(-50%)" right={searchBarIconRightPosition}>
              {shouldShowClearButton ? (
                <>
                  <IconButton
                    onClick={clearBtnClickHandler}
                    tabIndex={0}
                    variant="ghost"
                    icon={<MdClear />}
                    aria-label={ariaLabelClear}
                    data-testid="clear-btn"
                    fontSize={formMediumIcon}
                    _hover={{ backgroundColor: 'none' }}
                    _active={{ backgroundColor: 'none' }}
                    paddingX={formInterior}
                    id={'clear-search-button'}
                  />
                  <Divider orientation="vertical" color={borderDefault} />
                </>
              ) : null}
            </InputRightElement>
            <InputRightElement top="50%" transform="translateY(-50%)" right={searchBarQueryIconRightPosition}>
              <IconButton
                icon={<MdSearch />}
                aria-label={ariaLabelSearchButton}
                onClick={handleSearchClick}
                variant="ghost"
                data-testid="search-icon"
                fontSize={formMediumIcon}
                _hover={{ backgroundColor: 'none' }}
                _active={{ backgroundColor: 'none' }}
                paddingX={formInterior}
                id={'search-typeahead-button'}
              />
            </InputRightElement>
          </>
        </InputGroup>
        {isMobileSearchTypeahead ? (
          <Button
            paddingRight={0}
            fontSize={cancelBtnFontSize}
            fontWeight="normal"
            textDecor="underline"
            variant="transparent"
            color={pcxGreen}
            onClick={closeSearchDrawer}
          >
            {cancelText}
          </Button>
        ) : null}
      </HStack>
      {state.isOpen ? (
        <Box
          data-testid="typeahead-container"
          zIndex={1500}
          position="absolute"
          marginTop={isDesktop ? spacingSmall : null}
          width="100%"
          height={!isDesktop ? viewportHeight - 100 : undefined}
          borderTopWidth={isMobileSearchTypeaheadOpen ? width : null}
          borderTopStyle={isMobileSearchTypeaheadOpen ? style : null}
          borderTopColor={isMobileSearchTypeaheadOpen ? color : null}
          boxShadow={isDesktop ? elevationLow : null}
          overflowY="auto"
          ref={popoverRef}
        >
          <SearchDropdown>
            {isLoading && isGreaterThanMinCharLimit ? (
              <Box data-testid="typeahead-skeleton">
                <SearchMenuSkeleton />
              </Box>
            ) : (
              <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
            )}
          </SearchDropdown>
        </Box>
      ) : null}
    </Box>
  );
});

SearchBarTypeahead.displayName = 'SearchBarTypeahead';
