import { useTheme } from '@chakra-ui/react';
import { Icon } from '@ldfeplatform/componentlibrary.ui.atoms.icon';
import { Link } from '@ldfeplatform/componentlibrary.ui.atoms.link';
import { FilterChip } from '@ldfeplatform/componentlibrary.ui.molecules.filter-chip';
import { useRouter } from 'next/router';
import React from 'react';
import { IoReload } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import {
  FilterGroup,
  FilterValue,
  SearchResultsFilterChipsProps,
  SelectedFilter,
} from './search-results-filter-chips.interface';
import styles from './search-results-filter-chips.module.scss';

/**
 * SearchResultsFilterChips Component
 *
 * Displays active filters as chips and provides functionality to remove individual filters
 * or clear all filters at once. The component reads filter values from URL parameters and
 * renders them as interactive chips.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.filters - Contains filter groups and their values
 * @param {Function} props.onRemoveFilter - Callback function to handle filter removal
 */
const SearchResultsFilterChips: React.FC<SearchResultsFilterChipsProps> = ({ filters, onRemoveFilter }) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const {
    common: { common02Regular },
  } = useTheme();

  /**
   * Extracts selected filters from URL parameters and matches them with filter definitions
   *
   * @returns {Array} Array of selected filters with their group information
   */
  const getSelectedFilters = (): SelectedFilter[] => {
    if (!filters?.filterGroups) return [];

    const urlParams = new URLSearchParams(router.asPath.split('?')[1] || '');

    return filters.filterGroups
      .filter((filterGroup): filterGroup is FilterGroup => Boolean(filterGroup?.code && filterGroup?.values))
      .reduce<SelectedFilter[]>((acc, filterGroup) => {
        const paramName = filterGroup.code.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
        const selectedCodes = urlParams.get(paramName)?.split(',') || [];

        filterGroup.values
          .filter((value): value is FilterValue => Boolean(value?.code && selectedCodes.includes(value.code)))
          .forEach((value) => {
            acc.push({
              code: value.code,
              name: value.name,
              groupCode: filterGroup.code,
              groupName: filterGroup.name,
            });
          });

        return acc;
      }, []);
  };

  const selectedFilters = getSelectedFilters();

  /**
   * Handles clearing all active filters at once
   * Creates a special 'clear-all' filter object to trigger removal of all filters
   */
  const handleClearAll = () => {
    const clearAllFilter = {
      'clear-all': {
        code: 'clear-all',
        name: formatMessage({ id: 'clearAll' }),
        selected: false,
      },
    };
    onRemoveFilter(clearAllFilter);
  };

  // Don't render anything if there are no selected filters
  if (selectedFilters.length === 0) return null;

  return (
    <div className={styles.filterChipsContainer}>
      {/* Clear all filters button */}
      <Link
        className={styles.clearAllLink}
        onClick={handleClearAll}
        aria-label={formatMessage({ id: 'clearAllFilters' })}
        sx={{ ...common02Regular }}
      >
        <Icon data-testid="clear-all-icon" as={IoReload} aria-hidden="true" />
        {formatMessage({ id: 'clearAll' })}
      </Link>
      {/* Render individual filter chips */}
      {selectedFilters.map((filter) => (
        <FilterChip
          key={`${filter.groupCode}-${filter.code}`}
          filter={{
            code: filter.code,
            name: filter.name,
            count: 1,
            selected: true,
            disabled: false,
          }}
          filterGroupCode={filter.groupCode}
          handleRemoveFilter={onRemoveFilter}
          removeFilterLabel={`Remove ${filter.name}`}
        />
      ))}
    </div>
  );
};

export default SearchResultsFilterChips;
