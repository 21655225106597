import { useEffect } from 'react';

/**
 * Custom hook to lock or unlock the document's overflow style.
 * This is used to prevent scrolling when a modal, drawer etc. is open.
 *
 * @param isLocked - A boolean indicating whether to lock the overflow.
 */
export const useOverflowLock = (isLocked: boolean) => {
  useEffect(() => {
    // Get the root HTML element
    const htmlElement = document.documentElement;

    // If isLocked is true, set overflow to 'hidden' to prevent scrolling
    if (isLocked) {
      htmlElement.style.overflow = 'hidden';
    } else {
      // Otherwise, reset overflow to allow scrolling
      htmlElement.style.overflow = '';
    }

    // Cleanup function to reset overflow when the component unmounts
    return () => {
      htmlElement.style.overflow = '';
    };
  }, [isLocked]); // Re-run the effect whenever isLocked changes
};
