import React from 'react';
import { useTheme, ListItem } from '@chakra-ui/react';
import { useOption } from '@react-aria/listbox';

export type OptionProps = {
  item?: any;
  state?: any;
};

export const Option = (props: OptionProps) => {
  const { item, state } = props;
  const {
    background: { mid },
  } = useTheme();
  const ref = React.useRef(null);
  const { optionProps, isFocused } = useOption({ key: item.key }, state, ref);

  return (
    <ListItem
      {...optionProps}
      ref={ref}
      outline="none"
      cursor="point"
      backgroundColor={isFocused && mid}
      _selected={{ backgroundColor: mid }}
    >
      {item.rendered}
    </ListItem>
  );
};

Option.displayName = 'Option';
