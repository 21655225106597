import { NextRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FilterCallbackType, FilterItemType, FilterType } from '../store-locator-filter.interface';

/**
 * Custom hook to manage filter state and handle URL parameter synchronization
 *
 * @param filters - Object containing filter groups configuration
 * @param urlParams - URL parameters containing initial filter values
 * @returns Object containing selected filters, handlers for changes, and reset functionality
 */
export const useFilterState = (
  filters: { filterGroups?: FilterType[] },
  urlParams: Record<string, string>,
  router: NextRouter
) => {
  // State to track selected filters for each filter group
  const [selectedFilters, setSelectedFilters] = useState<Record<string, FilterItemType[]>>({});

  // Initialize filters from URL parameters when component mounts or URL changes
  useEffect(() => {
    const initialFilters: Record<string, FilterItemType[]> = {};

    filters?.filterGroups?.forEach((group: FilterType) => {
      // Convert camelCase filter codes to dash-case for URL parameter matching
      const urlParamKey = group.code.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);
      const urlValues = urlParams[urlParamKey]?.split(',') || [];

      // If URL contains values for this filter group, initialize them as selected
      if (urlValues.length > 0) {
        // Find all matching values from the filter group
        const matchingValues = group.values
          .filter((value: FilterItemType) => urlValues.includes(value.code))
          .map((value: FilterItemType) => ({
            ...value,
            selected: true,
          }));

        if (matchingValues.length > 0) {
          initialFilters[group.code] = matchingValues;
        }
      }
    });

    // Only update if we have initial filters or if URL params changed
    return Object.keys(initialFilters).length > 0 ? setSelectedFilters(initialFilters) : setSelectedFilters({});
  }, [filters, urlParams]);

  /**
   * Handle changes to filter selections
   * @param props - Object containing filter key and selected/deselected value
   */
  const handleFiltersChange = (props: FilterCallbackType) => {
    setSelectedFilters((prevFilters) => {
      const entries = Object.entries(props);
      if (entries.length === 0) return prevFilters;

      const [filterKey, filterValue] = entries[0];
      const currentValues = prevFilters[filterKey] || [];

      if (!(filterValue as FilterItemType).selected) {
        // Remove the deselected value
        return {
          ...prevFilters,
          [filterKey]: currentValues.filter((item) => item.code !== (filterValue as FilterItemType).code),
        };
      }

      // Check if value is already selected
      const isAlreadySelected = currentValues.some((item) => item.code === (filterValue as FilterItemType).code);

      if (isAlreadySelected) {
        return prevFilters;
      }

      // Add new value while preserving existing ones
      return {
        ...prevFilters,
        [filterKey]: [...currentValues, filterValue as FilterItemType],
      };
    });
  };

  /**
   * Reset all filters to their initial state
   */
  const resetFilters = () => {
    router.push(
      {
        pathname: router.pathname,
        query: {},
      },
      undefined,
      { shallow: true }
    );
    setSelectedFilters({});
  };

  return { selectedFilters, handleFiltersChange, resetFilters };
};
