import React from 'react';
import { useIntl } from 'react-intl';

import { useTheme } from '@chakra-ui/react';
import { IconButton } from '@ldfeplatform/componentlibrary.ui.atoms.button';
import { Divider } from '@ldfeplatform/componentlibrary.ui.atoms.divider';
import { Text } from '@ldfeplatform/componentlibrary.ui.atoms.text';

import SearchResultsContent from '@/components/search-results-content';
import SvgStoreLanding from '@/components/svg-store-landing';

import { MdFilterList } from 'react-icons/md';
import SearchResultsFilterChips from '../search-results-filter-chips';
import { ISearchResults } from './search-results.interface';
import styles from './search-results.module.scss';

const SearchResults: React.FC<ISearchResults> = ({
  storeLocatorResults,
  storeCount,
  hasResults,
  openFilters,
  contentRef,
  filters,
  onRemoveFilter = () => {},
}) => {
  const { formatMessage } = useIntl();
  const {
    palette: { sdmRed, black },
    desktop: { desktopLarge03Bold },
    sp05,
  } = useTheme();

  const renderSearchResultsHeader = () => (
    <>
      <Divider />
      <div className={styles.headingContainer}>
        <Text sx={{ ...desktopLarge03Bold }} as="h3">
          {hasResults
            ? formatMessage({ id: storeCount === 1 ? 'storeNearYou' : 'storesNearYou' }, { storeCount })
            : formatMessage({ id: 'searchForStores' })}
        </Text>
        {hasResults && (
          <IconButton
            icon={MdFilterList}
            variant={'secondary'}
            size="medium"
            color={sdmRed}
            aria-label={formatMessage({ id: 'title' })}
            px={sp05}
            onClick={openFilters}
          >
            <Text color={black}>{formatMessage({ id: 'title' })}</Text>
          </IconButton>
        )}
      </div>
    </>
  );

  return (
    <>
      {renderSearchResultsHeader()}
      {hasResults && filters?.filterGroups?.length > 0 ? (
        <SearchResultsFilterChips filters={filters} onRemoveFilter={onRemoveFilter} />
      ) : null}
      <Divider className={styles.divider} />
      {!hasResults ? <SvgStoreLanding /> : null}
      {hasResults
        ? storeLocatorResults.map((results: any, index: number) => (
            <SearchResultsContent
              forwardRef={(el: HTMLDivElement) => {
                contentRef(index, el);
              }}
              key={index}
              index={index}
              searchResults={results}
              view="desktop"
            />
          ))
        : null}
    </>
  );
};

export default SearchResults;
