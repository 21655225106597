import { Box } from '@ldfeplatform/componentlibrary.ui.atoms.box';
import { Button } from '@ldfeplatform/componentlibrary.ui.atoms.button';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
} from '@ldfeplatform/componentlibrary.ui.atoms.drawer';
import { Filters } from '@ldfeplatform/componentlibrary.ui.molecules.filter.filters';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { FilterItemType, FilterType, StoreLocatorFilterProps } from './store-locator-filter.interface';

import { Spinner } from '@/components/shared';
import { useViewportType } from '@/hooks';
import { useRouter } from 'next/router';
import { FILTER_ORDER, PHX_COMPLIANT_CODES, PHX_COMPLIANT_PARAMS, PHX_STORE_TYPES, SDM_STORE_TYPES } from './constants';
import { useFilterState } from './hooks/useFilterState';
import { useOverflowLock } from './hooks/useOverflowLock';
import styles from './store-locator-filter.module.scss';

const updateFiltersToMultiSelect = (filterGroups: FilterType[]) =>
  filterGroups.map((filter) => ({
    ...filter,
    multiSelect: true,
    // update all filters to multiSelect
  }));

const activatePhxComplianceOnFilters = (filterGroups: FilterType[]) =>
  filterGroups.filter((filter) =>
    [PHX_COMPLIANT_CODES.PHARMACY_SERVICES, PHX_COMPLIANT_CODES.STORE_HOURS].includes(filter.code)
  );

const StoreLocatorFilter = ({
  isFilterDrawerOpen,
  filters,
  applyFilters,
  closeFilterDrawer,
  isPhx,
  urlParams,
}: StoreLocatorFilterProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const [hasPhxCompliance, setHasPhxCompliance] = useState(false);
  const router = useRouter();
  const { selectedFilters, handleFiltersChange, resetFilters } = useFilterState(filters, urlParams, router);

  useOverflowLock(isFilterDrawerOpen);

  const updateUrlParams = (updatedFilters: Record<string, FilterItemType[]>) => {
    let newUrlParams = { ...urlParams };

    if (hasPhxCompliance) {
      newUrlParams = {
        [PHX_COMPLIANT_PARAMS.PHARMACY_SERVICES]: newUrlParams[PHX_COMPLIANT_PARAMS.PHARMACY_SERVICES],
        [PHX_COMPLIANT_PARAMS.STORE_HOURS]: newUrlParams[PHX_COMPLIANT_PARAMS.STORE_HOURS],
      };

      if (!newUrlParams[PHX_COMPLIANT_PARAMS.STORE_HOURS]) {
        delete newUrlParams[PHX_COMPLIANT_PARAMS.STORE_HOURS];
      }
    }

    Object.entries(updatedFilters).forEach(([key, values]) => {
      const paramKey = key.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);

      if (values.length === 0) {
        delete newUrlParams[paramKey]; // Remove param if no filters are selected for the respective filter
      } else {
        newUrlParams[paramKey] = values.map((v) => v.code).join(',');
      }
    });
    router.push(
      {
        pathname: router.pathname,
        query: newUrlParams,
      },
      undefined,
      { shallow: true }
    );
  };

  const handleApplyFilters = async () => {
    setIsLoading(true);
    let filtersToBeApplied = selectedFilters;
    if (hasPhxCompliance) {
      const phxCompliantSelectedFilters: Record<string, FilterItemType[]> = {
        pharmacyServices: selectedFilters?.pharmacyServices,
      };

      if (selectedFilters?.storeHours) {
        phxCompliantSelectedFilters.storeHours = selectedFilters.storeHours;
      }

      filtersToBeApplied = phxCompliantSelectedFilters;
    }

    await applyFilters(filtersToBeApplied);
    updateUrlParams(filtersToBeApplied);
    setIsLoading(false);
  };

  /**
   * Processes filters based on PHX compliance and URL parameters
   */
  const processedFilters = useMemo(
    () =>
      filters?.filterGroups
        ?.map(filterStoreTypes(isPhx))
        ?.map((filter: FilterType) => ({
          ...filter,
          values: [...filter.values].sort((a, b) => a.name.localeCompare(b.name)),
          hasSearchBar: filter.values.length > 10, //add search bar if more than 10 values
        }))
        ?.map(updateFromUrlParams(urlParams)) ?? [],
    [filters, isPhx, urlParams]
  );

  /**
   * Prepares filter groups with PHX compliance
   * @param hasPhxCompliance - Boolean indicating PHX compliance
   * @returns Processed filter groups
   */
  const prepareFilterGroups = useCallback(
    (hasPharmacyServicesSelected: boolean) => {
      let tempFilters = processedFilters;
      if (hasPharmacyServicesSelected) {
        tempFilters = activatePhxComplianceOnFilters(tempFilters);
      }

      // Cache indices in a Map
      const orderMap = new Map(FILTER_ORDER.map((code, index) => [code, index]));

      const sortedFilters = tempFilters.sort((a: FilterType, b: FilterType) => {
        const indexA = orderMap.get(a.code) ?? Infinity;
        const indexB = orderMap.get(b.code) ?? Infinity;
        return indexA - indexB;
      });

      return updateFiltersToMultiSelect(sortedFilters);
    },
    [processedFilters]
  );

  useEffect(() => {
    if (PHX_COMPLIANT_CODES.PHARMACY_SERVICES in selectedFilters && selectedFilters.pharmacyServices.length && isPhx) {
      setHasPhxCompliance(true);
    } else setHasPhxCompliance(false);
  }, [JSON.stringify(selectedFilters)]);

  const handleClearAllFilters = () => {
    resetFilters();
    closeFilterDrawer();
    applyFilters({});
  };

  const viewportType = useViewportType();
  const isDesktopView = viewportType === 'desktop';

  return !filters?.filterGroups ? null : (
    <Drawer
      isOpen={isFilterDrawerOpen}
      placement={isDesktopView ? 'right' : 'bottom'}
      size={'sm'}
      onClose={closeFilterDrawer}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton onClick={closeFilterDrawer} />

        <DrawerBody>
          <Box mx="1rem" className={styles.filterDrawerBody}>
            <Filters
              filterGroups={prepareFilterGroups(hasPhxCompliance)}
              title={formatMessage({ id: 'filterBy' })}
              handleOnChange={handleFiltersChange}
            />
          </Box>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px" className={styles.drawerFooter}>
          <Button
            mr={3}
            onClick={handleClearAllFilters}
            variant="secondary"
            isDisabled={!Object.values(selectedFilters).some((filters) => filters.length > 0)}
          >
            {formatMessage({ id: 'clearAll' })}
          </Button>
          <Button
            colorScheme="blue"
            onClick={isLoading ? () => {} : handleApplyFilters}
            variant="primary"
            isDisabled={!Object.values(selectedFilters).some((filters) => filters.length > 0) || isLoading}
          >
            {isLoading ? <Spinner width={20} height={20} style={{ margin: 0 }} /> : formatMessage({ id: 'apply' })}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

// Pure functions for filter transformations
const filterStoreTypes =
  (isPhx: boolean) =>
  (filter: FilterType): FilterType => {
    if (filter.code !== 'storeType') return filter;

    const filteredValues = filter.values.filter((value) =>
      isPhx
        ? PHX_STORE_TYPES.includes(value.name as (typeof PHX_STORE_TYPES)[number])
        : SDM_STORE_TYPES.includes(value.name as (typeof SDM_STORE_TYPES)[number])
    );

    return {
      ...filter,
      values: filteredValues,
    };
  };

const updateFromUrlParams =
  (urlParams: Record<string, string>) =>
  (filter: FilterType): FilterType => {
    const urlParamKey = filter.code.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);
    const urlValues = urlParams[urlParamKey]?.split(',') || [];

    if (!urlValues.length) return filter;

    return {
      ...filter,
      hasSearchBar: filter.values.length > 10,
      values: filter.values.map((value) => ({
        ...value,
        selected: urlValues.includes(value.code),
      })),
    };
  };

export default StoreLocatorFilter;
