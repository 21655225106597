import { Grid, GridItem, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { MdFilterList, MdMyLocation } from 'react-icons/md';
import { useIntl } from 'react-intl';

import { PAGE_ID } from '@/data/constants';
import MapComponent from '@/components/map';
import SearchResults from '@/components/search-results';
import { Spinner } from '@/components/shared';
import SvgStoreLanding from '@/components/svg-store-landing';
import TabComponent from '@/components/tab/tab.component';
import ViewList from '@/components/view-list/view-list.component';
import { useCommonContextProps, useViewportType } from '@/hooks';
import { isPharmaprixBanner } from '@/utils';
import { IconButton } from '@ldfeplatform/componentlibrary.ui.atoms.button';
import { Link } from '@ldfeplatform/componentlibrary.ui.atoms.link';
import { Text } from '@ldfeplatform/componentlibrary.ui.atoms.text';
import {
  E_ActionType,
  PHARMACISTS_OWNERS_LINK,
  SearchAlertType,
  STORE_LOCATOR_TAB_ICONS,
  TAB_STORE_LOCATOR,
} from './constants';
import { createInitialState, searchResultsReducer } from './state';
import { IError, IQueryItem, IStoreLocator } from './store-locator.interface';
import styles from './store-locator.module.scss';

import { getStoreLocatorSearchResults } from '@/api/store-locator';
import { Alert } from '@/components/alert';
import { SearchBarTypeahead } from '@/components/search-bar-typeahead/';
import { trackApplyFilter, trackSearchStore } from '@/utils/analytics/analytics-utils';
import { IGlobalContextFields } from '@/utils/analytics/analytics.interface';
import { Box } from '@ldfeplatform/componentlibrary.ui.atoms.box';
import { FilterReturnType } from '@ldfeplatform/componentlibrary.ui.molecules.filter-chip';
import { typographyBreakpoints } from '@ldfeplatform/componentlibrary.utils';
import { Item } from '@react-stately/collections';
import { QueryItem } from '../search-bar-typeahead/query-item';
import SearchResultsFilterChips from '../search-results-filter-chips';
import { FILTER_ORDER } from '../store-locator-filter/constants';
import StoreLocatorFilter from '../store-locator-filter/store-locator-filter.component';
import { FilterCallbackType } from '../store-locator-filter/store-locator-filter.interface';
import {
  fetchTypeaheadPlaces,
  geoLocationOptions,
  getCategoryIds,
  getGeoCodes,
  getSearchAlertProps,
  handleGeoLocationError,
} from './store-locator-utils';

const StoreLocator: React.FC<IStoreLocator> = ({ layout }) => {
  const router = useRouter();

  // Handle URL parameters for initial filter state
  const urlParams = Object.fromEntries(new URLSearchParams(router.asPath.split('?')[1] || ''));
  const hasUrlParams = Object.keys(urlParams).length > 0;

  const {
    desktop: { desktopLarge03Bold, large02, desktopLarge02Bold },
    mobile: { mobileLarge03Bold, mobileLarge04Bold },
    palette: { midGrey, black, sdmRed, lightestGrey },
    sp05,
    linkUnderline,
  } = useTheme();
  const isPhx = isPharmaprixBanner();
  const [{ mapPositions, searchResults, filters, storeCount, hasResults, loading }, dispatch] = useReducer(
    searchResultsReducer,
    layout,
    createInitialState
  );
  // state to prevent infinte loop when bounds are updated
  const [boundsUpdate, setBoundsUpdate] = useState<boolean>(true);
  const searchResultsRefs = useRef<{ [index: number]: HTMLDivElement }>({});
  const contentRef = (index: number, ref: HTMLDivElement) => {
    if (ref) {
      searchResultsRefs.current[index] = ref;
    }
  };
  const { formatMessage } = useIntl();
  const breakpointValue = useBreakpointValue(typographyBreakpoints, {
    ssr: false,
  });
  const { cartCount, locale, currentDevice, pcOptimumAccount } = useCommonContextProps();
  const globalContextFields: IGlobalContextFields = {
    cartCount,
    locale,
    currentDevice,
    pageId: PAGE_ID.STORE_LOCATOR,
    pcOptimumAccount,
  };
  const isDesktop = breakpointValue === 'Desktop';
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState<(string | number)[] | null>(null);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const { VIEW_LIST_BLACK_URL, VIEW_LIST_RED_URL, VIEW_MAP_BLACK_URL, VIEW_MAP_RED_URL } = STORE_LOCATOR_TAB_ICONS;
  const [navigationItems, setNavigationItems] = useState([
    {
      icon: {
        url: VIEW_LIST_BLACK_URL,
        altText: null,
      },
      displayText1: formatMessage({ id: 'viewList' }),
      url: '/view-list',
      isActive: true,
      iconUrls: { default: VIEW_LIST_BLACK_URL, activated: VIEW_LIST_RED_URL },
    },
    {
      icon: {
        url: VIEW_MAP_BLACK_URL,
        altText: null,
      },
      displayText1: formatMessage({ id: 'viewMap' }),
      url: '/view-map',
      isActive: false,
      iconUrls: { default: VIEW_MAP_BLACK_URL, activated: VIEW_MAP_RED_URL },
    },
  ]);
  const viewportType = useViewportType();
  const isDesktopView = viewportType === 'desktop';

  const handleLink = () => {
    window.location.href = PHARMACISTS_OWNERS_LINK;
  };

  const handleUrlParamFilters = async (coordinates: { latitude: string; longitude: string }) => {
    // Only apply URL filters once when coordinates and filter groups are available
    const { longitude, latitude } = coordinates;

    // Get current URL params
    const urlParams = new URLSearchParams(router.asPath.split('?')[1] || '');

    // First get the current filters before updating URL
    const paramsObj: Record<string, string> = {};
    urlParams.forEach((value, key) => {
      paramsObj[key] = value;
    });
    const categoryIds = getCategoryIds();

    // Then update coordinates in URL
    urlParams.set('latitude', latitude);
    urlParams.set('longitude', longitude);
    router.push(`${router.pathname}?${urlParams.toString()}`, undefined, { shallow: true });

    const data = await getStoreLocatorSearchResults({
      longitude,
      latitude,
      categoryId: categoryIds,
    });

    dispatch({ type: E_ActionType.update, payload: data });
    setSelectedFilters(categoryIds);
    setIsFilterDrawerOpen(false);
  };

  const handleLocationShareSuccess = async (location: GeolocationPosition) => {
    if (location?.coords) {
      dispatch({ type: E_ActionType.loading, payload: { layout: null } });
      const { latitude, longitude } = location.coords;
      if (latitude && longitude) {
        setSelectedCoordinates({ latitude: latitude.toString(), longitude: longitude.toString() });
        setBoundsUpdate(true);
        try {
          if (hasUrlParams) {
            await handleUrlParamFilters({ latitude: latitude.toString(), longitude: longitude.toString() });
            trackSearchStore(globalContextFields, 'auto-detect');
          } else {
            // Get current URL params to preserve existing filters
            const urlParams = new URLSearchParams(router.asPath.split('?')[1] || '');
            const existingFilters = getCategoryIds(); // Get filters before updating URL

            // Update coordinates while preserving existing params
            urlParams.set('latitude', latitude.toString());
            urlParams.set('longitude', longitude.toString());
            router.push(`${router.pathname}?${urlParams.toString()}`, undefined, { shallow: true });

            const data = await getStoreLocatorSearchResults({
              latitude,
              longitude,
              categoryId: existingFilters, // Use filters we got before URL update
            });
            dispatch({ type: E_ActionType.update, payload: data });
            trackSearchStore(globalContextFields, 'auto-detect');
          }
        } catch (error) {
          dispatch({ type: E_ActionType.error, payload: { layout: null, error: error as IError } });
        }
      }
    }
  };

  const handleMapInteraction = async (center: google.maps.LatLngLiteral, radius: number) => {
    if (center) {
      dispatch({ type: E_ActionType.loading, payload: { layout: null } });
      const { lat: latitude, lng: longitude } = center;
      if (latitude && longitude) {
        try {
          const categoryIds = getCategoryIds();
          const data = await getStoreLocatorSearchResults({
            longitude,
            latitude,
            radius: radius,
            categoryId: categoryIds,
          });
          dispatch({ type: E_ActionType.update, payload: data });

          setSelectedCoordinates({
            latitude: latitude.toString(),
            longitude: longitude.toString(),
          });

          // Update URL params with coordinates
          const urlParams = new URLSearchParams(router.asPath.split('?')[1] || '');
          urlParams.set('latitude', latitude.toString());
          urlParams.set('longitude', longitude.toString());
          router.push(`${router.pathname}?${urlParams.toString()}`, undefined, { shallow: true });
        } catch (error) {
          dispatch({ type: E_ActionType.error, payload: { layout: null, error: error as IError } });
        }
      }
    }
  };

  // Function to be used on 'Use My Location' button as well
  const handleUseMyLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(handleLocationShareSuccess, handleGeoLocationError, geoLocationOptions);
    onClearInput();
  }, []);

  const handlePinSelection = (index: number) => {
    const selectedContent = searchResultsRefs.current[index];
    if (selectedContent) {
      selectedContent.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleUseMyLocation();
    }
  }, [handleUseMyLocation]);

  // Search Typeahead
  /** Load Places Library once */
  const [placesLibaryLoaded, setPlacesLibraryLoaded] = useState(false);

  const loadPlacesLibrary = async () => {
    const placesLibrary = await google.maps.importLibrary('places');
    if (placesLibrary) {
      setPlacesLibraryLoaded(true);
    }
  };

  useEffect(() => {
    if (!placesLibaryLoaded) {
      loadPlacesLibrary();
    }
  }, [placesLibaryLoaded]);

  /** Array of suggested places returned by Places API */
  const [suggestedPlaces, setSuggestedPlaces] = useState<IQueryItem[]>([]);

  /** selected Address */
  const [selectedAddress, setSelectedAddress] = useState<string | number | null>(null);

  /** selected address coordinates */
  const [selectedCoordinates, setSelectedCoordinates] = useState<{ longitude: string; latitude: string } | null>({
    longitude: '',
    latitude: '',
  });

  /** error/alert for search bar */
  const [searchAlert, setSearchAlert] = useState<string>(SearchAlertType.None);

  /** Search Bar functions */
  const handleValueChange = useCallback((value: string | number) => {
    fetchTypeaheadPlaces({ setSuggestedPlaces, value, setSearchAlert });
    setSelectedAddress(value);
  }, []);

  const onClearInput = () => {
    setSuggestedPlaces([]);
    setSearchAlert(SearchAlertType.None);
    setSelectedAddress(null);
  };

  const onValueSubmit = async () => {
    if (searchAlert === SearchAlertType.None) {
      /* call geocode API to get geoCodes for address selected if no error occured */
      setBoundsUpdate(true);
      await getGeoCodes({
        selectedAddress,
        selectedCoordinates,
        setSelectedCoordinates,
        setSearchAlert,
        dispatch,
      });
    }
  };

  /** Searbar typeahead Mobile experience functions */
  const [searchDrawerIsOpen, setSearchDrawerIsOpen] = useState(false);
  const openSearchDrawer = () => {
    if (isDesktop || searchDrawerIsOpen) {
      return;
    }
    return setSearchDrawerIsOpen(true);
  };

  const closeSearchDrawer = () => {
    if (isDesktop || !searchDrawerIsOpen) {
      return;
    }
    onClearInput();
    setSearchDrawerIsOpen(false);
  };

  /** currentSearches returns a list of suggested address */
  const currentSearches =
    suggestedPlaces?.length > 0
      ? suggestedPlaces.map((item, _i) => (
          <Item key={item.plainText} textValue={item.plainText}>
            <Box w="100%">
              <QueryItem key={item.plainText} displayText={item.displayText} />
            </Box>
          </Item>
        ))
      : [];

  const searchBarTypeaheadProps = {
    placeholderText: formatMessage({ id: 'enterPostalCode' }),
    name: formatMessage({ id: 'search' }),
    ariaLabelInput: formatMessage({ id: 'search' }),
    ariaLabelSearchButton: formatMessage({ id: 'submitSearch' }),
    ariaLabelClear: formatMessage({ id: "clearSearch'" }),
    onValueSubmit: onValueSubmit,
    onSelectionChange: (value: React.SetStateAction<string | number | null>) => {
      setSelectedAddress(value);
    },
    showClearButtonWhenInputHasValue: true,
    allowsCustomValue: true,
    onValueChange: (value: string | number) => handleValueChange(value),
    onClearInput: onClearInput,
    openSearchDrawer: openSearchDrawer,
    closeSearchDrawer: closeSearchDrawer,
    globalContextFields: globalContextFields,
    children: currentSearches,
  };

  const applyFilters = useCallback(
    async (props: FilterCallbackType) => {
      // Extract category IDs from filter props, handling both string and number codes
      const categoryIds = Object.values(props).flatMap((group) =>
        Array.isArray(group) ? group.map((item: { code: string }) => item.code) : []
      );

      // Format filter data for analytics tracking
      // Converts camelCase to kebab-case and formats values for tracking
      const formatFilterForAnalytics = Object.entries(props).map(([key, values]) => ({
        category: key
          .split('')
          .map((char) => (char === char.toUpperCase() ? `-${char.toLowerCase()}` : char))
          .join(''),
        value: Array.isArray(values)
          ? values.map((item) => item.name.toLowerCase().split(' ').join('-')).join(', ')
          : '',
      }));
      trackApplyFilter(globalContextFields, formatFilterForAnalytics);

      // Exit if no coordinates are selected
      if (!selectedCoordinates) return;
      setBoundsUpdate(true);

      try {
        const { longitude, latitude } = selectedCoordinates;
        // Make API call with coordinates and selected category filters
        const data = await getStoreLocatorSearchResults({
          longitude,
          latitude,
          categoryId: categoryIds,
        });
        // Update store results and UI state
        dispatch({ type: E_ActionType.update, payload: data });
        setSelectedFilters(categoryIds);
        setIsFilterDrawerOpen(false);
      } catch (error) {
        dispatch({ type: E_ActionType.error, payload: { layout: null, error: error as IError } });
      }
    },
    [selectedCoordinates, dispatch, globalContextFields]
  );

  const closeFilterDrawer = () => {
    setIsFilterDrawerOpen(false);
  };

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isFilterDrawerOpen) {
      // Prevent scrolling when filter drawer is open
      htmlElement.style.overflow = 'hidden';
    } else {
      htmlElement.style.overflow = '';
    }

    return () => {
      htmlElement.style.overflow = '';
    };
  }, [isFilterDrawerOpen]);

  const handleRemoveFilter = (filter: FilterReturnType) => {
    let updatedFilters: string[] = [];
    const urlParams = new URLSearchParams(router.asPath.split('?')[1] || '');

    if ('clear-all' in filter) {
      setSelectedFilters([]);
      FILTER_ORDER.forEach((filterName) => {
        urlParams.delete(filterName.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`));
      });
    } else {
      const [groupCode] = Object.keys(filter);
      const paramName = groupCode.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

      // Get only the filters for this specific group
      const currentGroupFilters = urlParams.get(paramName)?.split(',') || [];

      // Remove the specific filter from this group
      const updatedGroupFilters = currentGroupFilters.filter((code) => code !== filter[groupCode].code);

      // Update URL params for this group only
      if (updatedGroupFilters.length > 0) {
        urlParams.set(paramName, updatedGroupFilters.join(','));
      } else {
        urlParams.delete(paramName);
      }

      // Get all current filters for state update
      updatedFilters = FILTER_ORDER.reduce((acc: string[], filterName) => {
        const param = filterName.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
        const values = urlParams.get(param)?.split(',') || [];
        return [...acc, ...values];
      }, []);

      setSelectedFilters(updatedFilters);
    }

    // Update URL
    const newUrl = `${router.pathname}?${urlParams.toString()}`;
    router.push(newUrl, undefined, { shallow: true });

    if (selectedCoordinates) {
      const { longitude, latitude } = selectedCoordinates;
      getStoreLocatorSearchResults({
        longitude,
        latitude,
        categoryId: updatedFilters,
      }).then((data) => {
        dispatch({ type: E_ActionType.update, payload: data });
      });
    }
  };

  return (
    <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string}>
      <StoreLocatorFilter
        isFilterDrawerOpen={isFilterDrawerOpen}
        filters={filters}
        applyFilters={applyFilters}
        closeFilterDrawer={closeFilterDrawer}
        isPhx={isPhx}
        urlParams={urlParams}
      />
      {isDesktop ? (
        <Text sx={{ ...desktopLarge02Bold }} as="h1" className={styles.findAStoreHeading}>
          {formatMessage({ id: 'findAStore' })}
        </Text>
      ) : (
        <Text sx={{ ...mobileLarge03Bold, px: '1.5rem' }} className={styles.findAStoreHeading}>
          {formatMessage({ id: hasResults ? 'findAStoreNearYou' : 'searchForStores' })}
        </Text>
      )}

      <div className={styles.searchBarLocationButtonContainer}>
        <Box className={styles.searchBarWrapper}>
          <SearchBarTypeahead {...searchBarTypeaheadProps} minimumCharacter={2} setSearchAlert={setSearchAlert}>
            {currentSearches}
          </SearchBarTypeahead>
          {searchAlert !== SearchAlertType.None ? (
            <div className={styles.alertContainer}>
              <Alert {...getSearchAlertProps(searchAlert, formatMessage)} />
            </div>
          ) : null}
        </Box>
        <IconButton
          className={styles.useMyLocationButton}
          icon={MdMyLocation}
          variant={'secondary'}
          size="large"
          aria-label={formatMessage({ id: 'useMyLocation' })}
          px={sp05}
          onClick={handleUseMyLocation}
          style={{
            border: 'none',
            backgroundColor: isDesktop ? 'none' : lightestGrey,
          }}
        >
          {isDesktop ? <Text color={black}>{formatMessage({ id: 'useMyLocation' })}</Text> : null}
        </IconButton>
      </div>

      <Grid
        {...(isDesktopView && { templateColumns: 'repeat(5, 1fr)' })}
        margin="0 auto"
        h="634px"
        className={styles.grid}
      >
        {isDesktopView && (
          <>
            <GridItem colSpan={2} borderBottom={`1px solid ${midGrey}`} overflow="auto">
              {loading ? (
                <Spinner />
              ) : (
                <SearchResults
                  storeLocatorResults={searchResults}
                  storeCount={storeCount}
                  hasResults={hasResults}
                  openFilters={() => setIsFilterDrawerOpen(true)}
                  contentRef={contentRef}
                  filters={filters}
                  onRemoveFilter={handleRemoveFilter}
                />
              )}
            </GridItem>
            <GridItem colSpan={3}>
              <MapComponent
                initialPosition={mapPositions?.[0]}
                positions={mapPositions}
                onPinSelected={(index) => handlePinSelection(index)}
                hasResults={hasResults}
                handleMapInteraction={handleMapInteraction}
                boundsUpdate={boundsUpdate}
                setBoundsUpdate={setBoundsUpdate}
              />
            </GridItem>
          </>
        )}
        {!isDesktopView && (
          <>
            {!selectedCoordinates ? <SvgStoreLanding /> : null}
            {selectedCoordinates ? (
              <GridItem colSpan={5} overflow="auto">
                <div className={styles.headingContainer}>
                  <Text sx={isDesktop ? { ...desktopLarge03Bold } : { ...mobileLarge04Bold }} as="h3">
                    {formatMessage({ id: 'storesNearYou' }, { storeCount })}
                  </Text>
                  <IconButton
                    icon={MdFilterList}
                    variant={'secondary'}
                    size="medium"
                    color={sdmRed}
                    aria-label={formatMessage({ id: 'title' })}
                    px={sp05}
                    onClick={() => setIsFilterDrawerOpen(true)}
                  >
                    <Text color={black}>{formatMessage({ id: 'title' })}</Text>
                  </IconButton>
                </div>
                <div className={styles.headingContainer}>
                  <SearchResultsFilterChips filters={filters} onRemoveFilter={handleRemoveFilter} />
                </div>
                <div className={styles.tabContainer}>
                  <TabComponent
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    navigationItems={navigationItems}
                    setNavigationItems={setNavigationItems}
                  />
                </div>

                <>
                  {activeIndex === TAB_STORE_LOCATOR.VIEW_LIST ? (
                    loading ? (
                      <Spinner />
                    ) : (
                      <ViewList storeLocatorResults={searchResults} />
                    )
                  ) : null}
                  {activeIndex === TAB_STORE_LOCATOR.VIEW_MAP ? (
                    <MapComponent
                      initialPosition={mapPositions?.[0]}
                      positions={mapPositions}
                      onPinSelected={(index) => handlePinSelection(index)}
                      hasResults
                      storeLocatorResults={searchResults}
                      contentRef={contentRef}
                      handleMapInteraction={handleMapInteraction}
                      boundsUpdate={boundsUpdate}
                      setBoundsUpdate={setBoundsUpdate}
                    />
                  ) : null}
                </>
              </GridItem>
            ) : null}
          </>
        )}
      </Grid>
      {isPhx ? (
        <div className={styles.pharmacistLink}>
          <Link sx={{ ...desktopLarge03Bold, fontSize: large02 }} onClick={handleLink} textDecoration={linkUnderline}>
            {formatMessage({ id: 'pharmacistOwners' })}
          </Link>
        </div>
      ) : null}
    </APIProvider>
  );
};

export default StoreLocator;
