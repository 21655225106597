import React, { memo, useState, useId } from 'react';
import { Card, CardContent } from '@ldfeplatform/componentlibrary.ui.atoms.card';
import { Flex } from '@ldfeplatform/componentlibrary.ui.atoms.flex';
import { Icon } from '@ldfeplatform/componentlibrary.ui.atoms.icon';
import { Box } from '@ldfeplatform/componentlibrary.ui.atoms.box';
import { MdExpandMore } from '@react-icons/all-files/md/MdExpandMore';
import { MdExpandLess } from '@react-icons/all-files/md/MdExpandLess';
import { Heading } from '@ldfeplatform/componentlibrary.ui.atoms.heading';
import { Text } from '@ldfeplatform/componentlibrary.ui.atoms.text';
import { Button } from '@ldfeplatform/componentlibrary.ui.atoms.button';
import { getFocusIndicator } from '@ldfeplatform/componentlibrary.utils';
import { Collapse, useTheme, CloseButton } from '@chakra-ui/react';
import { AlertVariantType, AlertProps } from '@ldfeplatform/componentlibrary.types';

import { MdErrorOutline } from '@react-icons/all-files/md/MdErrorOutline';

type BackgroundType = {
  backgroundColor: string;
  border: string;
};

type IconStylesType = {
  fill: string;
};

export const Alert = memo(
  ({ contentTitle, contentBody1, variantId, ctaText, contentBody2, hasCloseButton = false }: AlertProps) => {
    const {
      alertsBorderRadius,
      alertsTitle,
      alertsSubtext,
      alertBorder,
      alertsIconSize,
      alertsUnderline,
      alertsDetails,
      alertsInteriorSpacing,
      alertPadding,
      materialIcon = '',
      text: { default: defaultTextColor },
      interactive: { default: defaultInteractiveColor },
      surface: { error, warning, success, info },
      element: { spacingMedium: elementSpacing },
      content: { spacingLarge: contentSpacing },
      icon: { error: iconError, warning: iconWarning, success: iconSuccess, info: iconInfo, default: defaultIconColor },
    } = useTheme();

    const [show, setShow] = useState(false);
    const [isAlertOpen, setAlertOpen] = useState(true);
    const RightIcon = show ? MdExpandLess : MdExpandMore;

    const backgroundStyles: Record<AlertVariantType, BackgroundType> = {
      SUCCESS: {
        backgroundColor: success,
        border: `${alertBorder} solid ${iconSuccess}`,
      },
      ERROR: {
        backgroundColor: error,
        border: `${alertBorder} solid ${iconError}`,
      },
      WARNING: {
        backgroundColor: warning,
        border: `${alertBorder} solid ${iconWarning}`,
      },
      INFO: {
        backgroundColor: info,
        border: `${alertBorder} solid ${iconInfo}`,
      },
    };

    const iconStyles: Record<AlertVariantType, IconStylesType> = {
      SUCCESS: {
        fill: iconSuccess,
      },
      ERROR: {
        fill: iconError,
      },
      WARNING: {
        fill: iconWarning,
      },
      INFO: {
        fill: iconInfo,
      },
    };

    const focusIndicatorValues = getFocusIndicator(backgroundStyles[variantId].backgroundColor);

    const detailsId = useId();
    const buttonId = useId();

    const closeAlert = () => {
      setAlertOpen(false);
    };

    return isAlertOpen ? (
      <Card
        position="relative"
        variant="horizontal"
        borderRadius={alertsBorderRadius}
        sx={backgroundStyles[variantId]}
        padding={alertPadding}
        tabIndex={!contentBody2 ? -1 : undefined}
        style={{ width: 'inherit' }}
      >
        <CardContent>
          <Flex columnGap={alertsInteriorSpacing}>
            <Box>
              <Icon
                data-testid="status-icon"
                as={MdErrorOutline}
                boxSize={alertsIconSize}
                color={iconStyles[variantId]?.fill || ''}
                aria-hidden="true"
              />
            </Box>
            <Flex
              flexDirection="column"
              rowGap={alertsInteriorSpacing}
              sx={{ '.chakra-collapse': { rowGap: 0 } }}
              paddingRight={contentSpacing}
            >
              {contentTitle ? (
                <Heading data-testid="alert-title" as="h2" color={defaultTextColor} {...alertsTitle}>
                  {contentTitle}
                </Heading>
              ) : null}
              <Text color={defaultTextColor} data-testid="alert-content" {...alertsSubtext}>
                {contentBody1}
              </Text>
              {contentBody2 && ctaText && (
                <Button
                  id={`alert-button-${buttonId}`}
                  data-testid="alert-collapse-expand-button"
                  rightIcon={<RightIcon size={alertsIconSize} color={defaultInteractiveColor} />}
                  color={defaultInteractiveColor}
                  onClick={() => setShow(!show)}
                  variant="transparent"
                  textDecoration={alertsUnderline}
                  _focusVisible={focusIndicatorValues}
                  aria-expanded={show}
                  aria-controls={`alert-details-${detailsId}`}
                  padding={0}
                  maxW="fit-content"
                  minH={0}
                  sx={{
                    '.chakra-button__icon': {
                      marginLeft: 0,
                    },
                  }}
                  {...alertsDetails}
                >
                  {ctaText}
                </Button>
              )}
              <Collapse in={show} startingHeight={0}>
                <Text
                  id={`alert-details-${detailsId}`}
                  color={defaultTextColor}
                  data-testid="alert-content-details"
                  aria-labelledby={`alert-button-${buttonId}`}
                  {...alertsSubtext}
                >
                  {contentBody2}
                </Text>
              </Collapse>
            </Flex>
            {hasCloseButton && (
              <CloseButton
                position="absolute"
                right={elementSpacing}
                top={elementSpacing}
                color={defaultIconColor}
                width={alertsIconSize}
                height={alertsIconSize}
                padding={alertPadding}
                onClick={closeAlert}
                data-testid="alert-close-button"
              />
            )}
          </Flex>
        </CardContent>
      </Card>
    ) : null;
  }
);

Alert.displayName = 'Alert';
