//@ts-ignore
import React from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/react';
import PageNotFound from '@/pages/404';
import { useSDMCartContext, useSDMCustomerContext } from '@ldp/sdm-contexts';

import NonSduiComponent from '@/components/non-sdui/non-sdui.component';
import StoreLocator from '@/components/store-locator';

import { isPharmaprixBanner } from '@/utils';
import { HOST, PAGE_ID } from '@/data/constants';
import { useTrackPageView } from '@/hooks';

export const getServerSideProps = () => {
  return { props: { layout: null } };
};

const StoreLocatorPage = ({ layout, error }: { layout: null; error?: boolean }) => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { cartCount, isInitialCartCountUpdated, setPageId } = useSDMCartContext();
  const { pcOptimumAccount } = useSDMCustomerContext();

  setPageId(PAGE_ID.STORE_LOCATOR);
  useTrackPageView(cartCount, PAGE_ID.STORE_LOCATOR, pcOptimumAccount, isInitialCartCountUpdated);

  if (error) {
    return <PageNotFound />;
  }

  const isPharmaprix = isPharmaprixBanner();
  const currentUrlBase = isPharmaprix ? HOST.PHX_HOME : HOST.SDM_HOME;
  const hasPharmacyFiltersSelected = !!router.query['pharmacy-services'] && isPharmaprixBanner();
  const STORE_LOCATOR = 'store-locator';

  const constructStoreLocatorMetaData = () => {
    const seoTitle = isPharmaprix ? 'storeLocatorPhxTitle' : 'storeLocatorSdmTitle';
    const seoDescription = isPharmaprix ? 'storeLocatorPhxDescription' : 'storeLocatorSdmDescription';
    return {
      title: formatMessage({ id: seoTitle }),
      description: formatMessage({ id: seoDescription }),
      addMetaNoRobots: false,
      canonicalUrlSlug: STORE_LOCATOR,
      jsonLd: null,
    };
  };
  const seoMetaData = constructStoreLocatorMetaData();
  const seoWrapperData = {
    seoMetaData,
    currentUrlBase,
    jsonLdScript: '',
  };

  return (
    <NonSduiComponent {...seoWrapperData} isPhxPharmacyTab={hasPharmacyFiltersSelected}>
      <StoreLocator layout={layout} />
    </NonSduiComponent>
  );
};

export default StoreLocatorPage;
