import React from 'react';
import { useTheme } from '@chakra-ui/react';
import { QueryItemProps } from '@ldfeplatform/componentlibrary.types';
import { MarkDownReader } from '@ldfeplatform/componentlibrary.ui.atoms.mark-down-reader';
import { Text } from '@ldfeplatform/componentlibrary.ui.atoms.text';
import { Box } from '@ldfeplatform/componentlibrary.ui.atoms.box';
import { Flex } from '@ldfeplatform/componentlibrary.ui.atoms.flex';
import { Icon } from '@ldfeplatform/componentlibrary.ui.atoms.icon';
import { Image } from '@ldfeplatform/componentlibrary.ui.atoms.image';
// will be updated to all files later
import { MdOutlineHistory, MdOutlineSearch } from 'react-icons/md';

export const QueryItem = ({ displayText, leftIcon, leftImage }: QueryItemProps) => {
  const {
    queryItemBorderWidth,
    border: { default: borderDefault },
    search: { itemPaddingLeft, itemPaddingRight },
    element: { spacingMedium },
    itemTextPaddingTop,
    itemTextPaddingBottom,
    text: { default: textDefault },
    leftIndicatorSize,
    queryImageSize,
    queryItemSuggested,
    queryIconSize,
    icon: { deemphasized },
  } = useTheme();

  const { ...restOfQueryItemSuggested } = queryItemSuggested;

  return (
    <Flex
      data-testid="query-item"
      gap={spacingMedium}
      borderBottom={`${queryItemBorderWidth} solid ${borderDefault}`}
      paddingLeft={itemPaddingLeft}
      paddingRight={itemPaddingRight}
      alignItems="center"
    >
      {(leftImage || leftIcon) && (
        <Flex
          data-testid="left-indicator-container"
          boxSize={leftIndicatorSize}
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
        >
          {leftImage ? (
            <Image
              data-testid="left-image"
              boxSize={queryImageSize}
              src={leftImage.url}
              alt={leftImage.altText || ''}
              loading="lazy"
            />
          ) : (
            <Icon
              as={leftIcon === 'Timer' ? MdOutlineHistory : MdOutlineSearch}
              data-testid="left-icon"
              boxSize={queryIconSize}
              color={deemphasized}
              aria-hidden
            />
          )}
        </Flex>
      )}
      <Box
        data-testid="item-text-container"
        pt={itemTextPaddingTop}
        pb={itemTextPaddingBottom}
        color={textDefault}
        {...restOfQueryItemSuggested}
      >
        <Text noOfLines={2} as="span">
          <MarkDownReader markDown={displayText} />
        </Text>
      </Box>
    </Flex>
  );
};
