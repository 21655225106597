import React from 'react';

import { ISvgStoreLanding } from './svg-store-landing.interface';
import styles from './svg-store-landing.module.scss';
import { isPharmaprixBanner } from '@/utils';

const SvgStoreLanding: React.FC<ISvgStoreLanding> = ({ height = 250, width = 250 }) => {
  if (isPharmaprixBanner()) {
    return (
      <div className={styles.svgContainer}>
        <svg width={width} height={height} viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M200.279 200.276C145.184 255.379 66.8565 266.364 25.2779 224.779C-16.2926 183.202 -5.30952 104.829 49.7773 49.7175C104.872 -5.38604 183.257 -16.3545 224.779 25.2143C266.349 66.7831 255.399 145.172 200.279 200.276Z"
            fill="#EEEEEE"
          />
          <rect x="5.99219" y="76.0942" width="231.907" height="2.28125" fill="#D8D8D8" />
          <rect x="7.14648" y="78.375" width="228.445" height="114.443" fill="#F2F2F2" />
          <path d="M54.0664 92.8218V133.314" stroke="#E3E3E3" stroke-width="0.180363" />
          <path d="M80.2188 92.8218V133.314" stroke="#E3E3E3" stroke-width="0.180363" />
          <path d="M106.369 92.8218V133.314" stroke="#E3E3E3" stroke-width="0.180363" />
          <path d="M132.523 92.8218V133.314" stroke="#E3E3E3" stroke-width="0.180363" />
          <path d="M158.67 92.8218V133.314" stroke="#E3E3E3" stroke-width="0.180363" />
          <path d="M184.826 92.8218V133.314" stroke="#E3E3E3" stroke-width="0.180363" />
          <path d="M210.977 92.8218V133.314" stroke="#E3E3E3" stroke-width="0.180363" />
          <rect x="7.14648" y="78.375" width="228.445" height="14.4479" fill="#EAEAEA" />
          <path d="M34.0664 65.4487H207.131L203.285 112.595H37.9123L34.0664 65.4487Z" fill="#CB333B" />
          <path
            d="M64.9888 87.088V88.9263H66.6973C66.8577 88.9155 67.0311 88.9155 67.1925 88.8754C67.6458 88.7631 67.9112 88.4347 67.9112 87.9966C67.9112 87.1488 67.0451 87.1071 66.671 87.088H64.9888ZM62 85.5653H67.3917C68.4189 85.5967 69.3135 85.6363 70.0752 86.2386C70.7691 86.8008 70.8622 87.4659 70.8622 87.9251C70.8622 88.5284 70.7023 89.1811 69.9681 89.7632C69.18 90.3871 68.3133 90.4174 67.2841 90.4392H64.9888V92.7886H62V85.5653"
            fill="white"
          />
          <path
            d="M71.7158 85.5755H74.6916V88.2005H77.5355V85.5755H80.5392V92.7886H77.5355V90.0088H74.6916V92.7886H71.7158V85.5755"
            fill="white"
          />
          <path
            d="M86.272 87.6291L85.2444 90.1736H87.2732L86.272 87.6291ZM84.5898 85.5755H87.9811L91.5051 92.7886H88.3009L87.888 91.7858H84.6038L84.1903 92.7886H80.9863L84.5898 85.5755"
            fill="white"
          />
          <path
            d="M94.9487 87.0777V88.7631H96.1904C96.6843 88.7631 97.6311 88.7415 97.6311 87.9251C97.6311 87.0973 96.6965 87.0777 96.1764 87.0777H94.9487ZM91.959 85.5653H96.6578C97.5509 85.5653 98.4058 85.6054 98.9802 85.7907C100.234 86.1984 100.608 87.1807 100.608 87.8746C100.608 88.1505 100.568 88.7935 99.887 89.3243C99.514 89.6303 99.1134 89.7442 98.6726 89.877L100.968 92.7886H97.6579L95.8304 90.2425H94.9487V92.7886H91.959V85.5653"
            fill="white"
          />
          <path
            d="M101.53 85.5755H105.788L106.95 88.9052C107.162 89.6206 107.203 89.7843 107.336 90.4392C107.51 89.5496 107.564 89.3654 107.777 88.6705L108.845 85.5755H113.116V92.7886H110.273V88.619C110.286 88.0893 110.3 87.9549 110.353 87.3423C110.193 87.8828 110.166 87.9858 109.98 88.5078L108.418 92.7886H106.201L104.747 88.7322C104.546 88.1505 104.534 88.1392 104.306 87.3845C104.346 87.8329 104.36 87.9446 104.36 88.3544V92.7886H101.53V85.5755"
            fill="white"
          />
          <path
            d="M118.856 87.6291L117.827 90.1736H119.857L118.856 87.6291ZM117.174 85.5755H120.564L124.087 92.7886H120.885L120.47 91.7858H117.186L116.773 92.7886H113.569L117.174 85.5755"
            fill="white"
          />
          <path
            d="M127.537 87.088V88.9263H129.245C129.406 88.9155 129.579 88.9155 129.739 88.8754C130.193 88.7631 130.46 88.4347 130.46 87.9966C130.46 87.1488 129.593 87.1071 129.218 87.088H127.537ZM124.546 85.5653H129.939C130.968 85.5967 131.862 85.6363 132.623 86.2386C133.316 86.8008 133.409 87.4659 133.409 87.9251C133.409 88.5284 133.25 89.1811 132.515 89.7632C131.728 90.3871 130.861 90.4174 129.832 90.4392H127.537V92.7886H124.546V85.5653"
            fill="white"
          />
          <path
            d="M137.246 87.0777V88.7631H138.487C138.981 88.7631 139.928 88.7415 139.928 87.9251C139.928 87.0973 138.994 87.0777 138.474 87.0777H137.246ZM134.256 85.5653H138.953C139.849 85.5653 140.704 85.6054 141.277 85.7907C142.531 86.1984 142.906 87.1807 142.906 87.8746C142.906 88.1505 142.864 88.7935 142.185 89.3243C141.811 89.6303 141.41 89.7442 140.97 89.877L143.266 92.7886H139.956L138.126 90.2425H137.246V92.7886H134.256V85.5653Z"
            fill="white"
          />
          <path d="M143.823 92.7886H146.813V85.5755H143.823V92.7886Z" fill="white" />
          <path
            d="M153.821 85.5755H157.226L154.25 88.9052L157.867 92.7886H154.343L152.3 90.1931L150.218 92.7886H146.72L150.365 88.9155L147.308 85.5755H150.805L152.3 87.5575L153.821 85.5755"
            fill="white"
          />
          <path
            d="M175.56 94.5779C171.749 98.3709 166.328 99.1256 163.452 96.2644C160.577 93.4043 161.336 88.0084 165.147 84.2164C168.96 80.4235 174.38 79.6698 177.255 82.53C180.131 85.3902 179.372 90.7861 175.56 94.5779Z"
            fill="#5BBAEC"
          />
          <path
            d="M166.623 85.5522H175.21C175.636 85.5522 175.98 85.893 175.98 86.3181C175.98 86.7413 175.636 87.0821 175.21 87.0821H166.623C166.196 87.0821 165.853 86.7413 165.853 86.3181C165.853 85.893 166.196 85.5522 166.623 85.5522ZM174.027 95.8838H166.352C166.017 95.8838 165.723 95.6707 165.619 95.3535C165.516 95.0385 165.628 94.6926 165.896 94.4969L168.254 92.7889C168.745 92.4338 169.476 92.7672 169.476 93.4076C169.476 93.6455 169.368 93.8771 169.161 94.0286C169.161 94.0286 168.854 94.2529 168.715 94.3518C170.304 94.3518 171.432 94.3518 172.98 94.3518C170.724 92.6294 166.154 89.1392 166.154 89.1392C165.983 89.0064 165.853 88.8077 165.853 88.5019C165.853 88.1096 166.212 87.7647 166.589 87.7647H175.226C175.639 87.7647 175.98 88.1158 175.98 88.5307C175.98 88.8046 175.838 89.0054 175.698 89.1207L173.436 90.9922C173.109 91.2642 172.624 91.2198 172.351 90.8934C172.231 90.7514 172.173 90.5773 172.173 90.4055C172.173 90.185 172.269 89.9668 172.45 89.8145C172.45 89.8145 172.826 89.5035 173.076 89.2967C171.513 89.2967 170.519 89.2967 168.891 89.2967C170.932 90.8553 174.95 93.9009 175.678 94.4579C175.416 94.776 174.424 95.6676 174.027 95.8838"
            fill="white"
          />
          <path
            d="M172.78 83.6947L174.096 82.835C174.455 82.6013 174.937 82.7001 175.172 83.0584C175.408 83.4157 175.309 83.8944 174.948 84.1292L173.632 84.9879C173.274 85.2206 172.791 85.1217 172.556 84.7655C172.319 84.4082 172.42 83.9284 172.78 83.6947Z"
            fill="white"
          />
          <path
            d="M177.513 95.7802H177.616C177.734 95.7802 177.833 95.7369 177.833 95.6278C177.833 95.5495 177.776 95.4718 177.616 95.4718C177.57 95.4718 177.538 95.4759 177.513 95.4795V95.7802ZM177.513 96.2713H177.371V95.3838C177.446 95.373 177.517 95.3621 177.624 95.3621C177.758 95.3621 177.847 95.391 177.9 95.4296C177.954 95.4687 177.982 95.5284 177.982 95.6139C177.982 95.7302 177.904 95.8013 177.808 95.8291V95.8363C177.887 95.8507 177.939 95.9212 177.957 96.052C177.979 96.1894 178 96.2435 178.014 96.2713H177.865C177.843 96.2435 177.822 96.1616 177.804 96.0453C177.783 95.932 177.726 95.8898 177.613 95.8898H177.513V96.2713ZM177.663 95.1367C177.31 95.1367 177.023 95.4373 177.023 95.808C177.023 96.1868 177.31 96.4834 177.666 96.4834C178.022 96.4864 178.306 96.1868 178.306 95.8121C178.306 95.4373 178.022 95.1367 177.666 95.1367H177.663ZM177.666 95.0126C178.103 95.0126 178.452 95.3663 178.452 95.808C178.452 96.2568 178.103 96.6074 177.663 96.6074C177.226 96.6074 176.87 96.2568 176.87 95.808C176.87 95.3663 177.226 95.0126 177.663 95.0126H177.666Z"
            fill="#5BBAEC"
          />
          <rect x="33.2988" y="59.7446" width="174.988" height="5.70312" fill="#EBEBEB" />
          <rect x="32.1484" y="57.4639" width="176.141" height="2.28125" fill="#D8D8D8" />
          <rect x="9.06836" y="133.504" width="224.984" height="57.0312" fill="#CFCFCF" />
          <rect x="67.1426" y="123.144" width="106.496" height="8.76316" fill="#CB333B" />
          <rect x="84.6172" y="131.908" width="73.3376" height="56.8145" fill="url(#paint0_linear_3309_63815)" />
          <rect
            x="89.0977"
            y="123.144"
            width="65.5776"
            height="8.96182"
            transform="rotate(90 89.0977 123.144)"
            fill="#CB333B"
          />
          <rect
            x="162.438"
            y="123.144"
            width="65.5776"
            height="8.96182"
            transform="rotate(90 162.438 123.144)"
            fill="#CB333B"
          />
          <rect opacity="0.2" x="89.0977" y="131.908" width="64.3758" height="17.9645" fill="white" />
          <rect x="120.83" y="131.908" width="0.597455" height="56.8145" fill="#CDCDCD" />
          <rect x="89.1348" y="132.06" width="0.536384" height="56.6453" fill="#D9D9D9" />
          <rect
            x="153.502"
            y="131.798"
            width="0.524496"
            height="64.3661"
            transform="rotate(90 153.502 131.798)"
            fill="#D9D9D9"
          />
          <rect
            x="11.3549"
            y="135.768"
            width="68.5044"
            height="52.8879"
            fill="#A5E1FF"
            stroke="white"
            stroke-width="0.721451"
          />
          <path d="M12.584 187.5V136.848H27.565L12.584 187.5Z" fill="#BAE8FF" />
          <path d="M34.457 187.5V136.848H49.4381L34.457 187.5Z" fill="#BAE8FF" />
          <path d="M58.4238 187.5V136.848H73.4049L58.4238 187.5Z" fill="#BAE8FF" />
          <rect
            x="162.882"
            y="135.768"
            width="68.889"
            height="52.8879"
            fill="#A5E1FF"
            stroke="white"
            stroke-width="0.721451"
          />
          <path d="M163.891 187.5V136.848H178.872L163.891 187.5Z" fill="#BAE8FF" />
          <path d="M186.062 187.499V136.848H201.044L186.062 187.499Z" fill="#BAE8FF" />
          <path d="M210.035 187.5V136.848H225.016L210.035 187.5Z" fill="#BAE8FF" />
          <rect x="10.9941" y="148.333" width="69.2258" height="1.52083" fill="white" />
          <rect
            x="34.4531"
            y="135.786"
            width="52.8489"
            height="1.53835"
            transform="rotate(90 34.4531 135.786)"
            fill="white"
          />
          <rect
            x="58.2949"
            y="135.786"
            width="52.8489"
            height="1.53835"
            transform="rotate(90 58.2949 135.786)"
            fill="white"
          />
          <rect
            x="185.98"
            y="135.786"
            width="52.8489"
            height="1.53835"
            transform="rotate(90 185.98 135.786)"
            fill="white"
          />
          <rect
            x="209.822"
            y="135.786"
            width="52.8489"
            height="1.53835"
            transform="rotate(90 209.822 135.786)"
            fill="white"
          />
          <rect x="162.521" y="148.333" width="69.2258" height="1.52083" fill="white" />
          <path d="M7.33789 112.785H235.399" stroke="#E3E3E3" stroke-width="0.180363" />
          <path d="M27.9141 92.8218V133.314" stroke="#E3E3E3" stroke-width="0.180363" />
          <path d="M7.05078 77.7031V190.316" stroke="#D8D8D8" stroke-width="0.28103" />
          <rect x="9.92578" y="177.212" width="2.25557" height="12.0414" fill="#7E6B4E" />
          <rect x="28.8691" y="177.212" width="2.25557" height="12.0414" fill="#7E6B4E" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.6289 187.505C12.6289 185.761 11.2151 184.348 9.4711 184.348C7.7271 184.348 6.3133 185.761 6.3133 187.505V187.915H6.27614C4.55199 187.915 3.1543 189.313 3.1543 191.037C3.1543 192.761 4.55199 194.159 6.27613 194.159H9.41223C9.43181 194.159 9.45144 194.159 9.4711 194.159C9.49077 194.159 9.5104 194.159 9.52998 194.159H13.1148C14.8389 194.159 16.2366 192.761 16.2366 191.037C16.2366 189.313 14.8389 187.915 13.1148 187.915H12.6289V187.505Z"
            fill="#768E11"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.18585 190.59C3.40206 192.103 4.70325 193.267 6.27613 193.267H9.41223C9.43181 193.267 9.45144 193.267 9.4711 193.267C9.49077 193.267 9.5104 193.267 9.52998 193.267H13.1148C14.6877 193.267 15.9889 192.103 16.2051 190.59C16.2259 190.736 16.2366 190.885 16.2366 191.036C16.2366 192.76 14.8389 194.158 13.1148 194.158H9.52998C9.5104 194.158 9.49077 194.158 9.4711 194.158C9.45144 194.158 9.43181 194.158 9.41223 194.158H6.27613C4.55199 194.158 3.1543 192.76 3.1543 191.036C3.1543 190.885 3.16505 190.736 3.18585 190.59Z"
            fill="#6C7B17"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.4258 187.505C28.4258 185.761 27.012 184.348 25.268 184.348C23.524 184.348 22.1102 185.761 22.1102 187.505V187.915H22.073C20.3489 187.915 18.9512 189.313 18.9512 191.037C18.9512 192.761 20.3489 194.159 22.073 194.159H25.2091C25.2287 194.159 25.2483 194.159 25.268 194.159C25.2876 194.159 25.3073 194.159 25.3269 194.159H28.9117C30.6358 194.159 32.0335 192.761 32.0335 191.037C32.0335 189.313 30.6358 187.915 28.9117 187.915H28.4258V187.505Z"
            fill="#768E11"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.9827 190.59C19.1989 192.103 20.5001 193.267 22.073 193.267H25.2091C25.2287 193.267 25.2483 193.267 25.268 193.267C25.2876 193.267 25.3073 193.267 25.3269 193.267H28.9117C30.4845 193.267 31.7857 192.103 32.0019 190.59C32.0227 190.736 32.0335 190.885 32.0335 191.036C32.0335 192.76 30.6358 194.158 28.9117 194.158H25.3269C25.3073 194.158 25.2876 194.158 25.268 194.158C25.2483 194.158 25.2287 194.158 25.2091 194.158H22.073C20.3489 194.158 18.9512 192.76 18.9512 191.036C18.9512 190.885 18.9619 190.736 18.9827 190.59Z"
            fill="#6C7B17"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.3984 187.505C19.3984 185.761 17.9846 184.348 16.2406 184.348C14.4966 184.348 13.0828 185.761 13.0828 187.505V187.915H13.0457C11.3215 187.915 9.92383 189.313 9.92383 191.037C9.92383 192.761 11.3215 194.159 13.0457 194.159H16.1818C16.2013 194.159 16.221 194.159 16.2406 194.159C16.2603 194.159 16.2799 194.159 16.2995 194.159H19.8843C21.6085 194.159 23.0062 192.761 23.0062 191.037C23.0062 189.313 21.6085 187.915 19.8843 187.915H19.3984V187.505Z"
            fill="#9FCC76"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.5391 187.505C36.5391 185.761 35.1253 184.348 33.3813 184.348C31.6373 184.348 30.2235 185.761 30.2235 187.505V187.915H30.1863C28.4621 187.915 27.0645 189.313 27.0645 191.037C27.0645 192.761 28.4621 194.159 30.1863 194.159H33.3224C33.342 194.159 33.3616 194.159 33.3813 194.159C33.4009 194.159 33.4206 194.159 33.4401 194.159H37.0249C38.7491 194.159 40.1468 192.761 40.1468 191.037C40.1468 189.313 38.7491 187.915 37.0249 187.915H36.5391V187.505Z"
            fill="#88B01D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.98966 190.364C10.2956 191.767 11.545 192.818 13.0398 192.818H16.1759C16.1955 192.818 16.2151 192.818 16.2348 192.818C16.2544 192.818 16.2741 192.818 16.2937 192.818H19.8785C21.3732 192.818 22.6226 191.767 22.9286 190.364C22.9756 190.58 23.0003 190.803 23.0003 191.033C23.0003 192.757 21.6026 194.154 19.8785 194.154H16.2937C16.2741 194.155 16.2544 194.155 16.2348 194.155C16.2151 194.155 16.1955 194.155 16.1759 194.154H13.0398C11.3157 194.154 9.91797 192.757 9.91797 191.033C9.91797 190.803 9.94271 190.58 9.98966 190.364Z"
            fill="#95B96F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.1361 190.366C27.4421 191.769 28.6915 192.82 30.1863 192.82H33.3224C33.342 192.82 33.3616 192.82 33.3813 192.82C33.4009 192.82 33.4206 192.82 33.4401 192.82H37.0249C38.5197 192.82 39.7691 191.769 40.0751 190.366C40.122 190.582 40.1468 190.805 40.1468 191.034C40.1468 192.759 38.7491 194.156 37.0249 194.156H33.4401C33.4206 194.157 33.4009 194.157 33.3813 194.157C33.3616 194.157 33.342 194.157 33.3224 194.156H30.1863C28.4621 194.156 27.0645 192.759 27.0645 191.034C27.0645 190.805 27.0892 190.582 27.1361 190.366Z"
            fill="#809922"
          />
          <ellipse cx="10.8268" cy="169.184" rx="10.8268" ry="10.7034" fill="#839F16" />
          <ellipse cx="10.825" cy="166.955" rx="9.47341" ry="8.47356" fill="#88B01D" />
          <ellipse cx="8.34407" cy="164.949" rx="5.18782" ry="5.12874" fill="#A7C536" />
          <rect x="23.0078" y="155.806" width="14.4357" height="24.0828" rx="7.21783" fill="#9FCC76" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.0078 168.213V172.671C23.0078 176.657 26.2393 179.889 30.2256 179.889C34.2119 179.889 37.4435 176.657 37.4435 172.671V168.213C37.4435 172.199 34.2119 175.431 30.2256 175.431C26.2393 175.431 23.0078 172.199 23.0078 168.213Z"
            fill="#95B96F"
          />
          <rect x="24.8125" y="160.265" width="4.06003" height="9.36552" rx="2.03002" fill="#AED288" />
          <path d="M236.01 77.7031V190.316" stroke="#D8D8D8" stroke-width="0.28103" />
          <rect x="213.672" y="177.212" width="2.25557" height="12.0414" fill="#7E6B4E" />
          <rect x="232.615" y="177.212" width="2.25557" height="12.0414" fill="#7E6B4E" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M216.379 187.505C216.379 185.761 214.965 184.347 213.221 184.347C211.477 184.347 210.063 185.761 210.063 187.505V187.914H210.026C208.302 187.914 206.904 189.312 206.904 191.036C206.904 192.76 208.302 194.158 210.026 194.158H213.162C213.182 194.158 213.201 194.159 213.221 194.159C213.241 194.159 213.26 194.158 213.28 194.158H216.865C218.589 194.158 219.987 192.76 219.987 191.036C219.987 189.312 218.589 187.914 216.865 187.914H216.379V187.505Z"
            fill="#768E11"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M206.936 190.59C207.152 192.103 208.453 193.266 210.026 193.266H213.162C213.182 193.267 213.201 193.267 213.221 193.267C213.241 193.267 213.26 193.267 213.28 193.266H216.865C218.438 193.266 219.739 192.103 219.955 190.59C219.976 190.735 219.987 190.884 219.987 191.035C219.987 192.76 218.589 194.157 216.865 194.157H213.28C213.26 194.158 213.241 194.158 213.221 194.158C213.201 194.158 213.182 194.158 213.162 194.157H210.026C208.302 194.157 206.904 192.76 206.904 191.035C206.904 190.884 206.915 190.735 206.936 190.59Z"
            fill="#6C7B17"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M232.168 187.505C232.168 185.761 230.754 184.347 229.01 184.347C227.266 184.347 225.852 185.761 225.852 187.505V187.914H225.815C224.091 187.914 222.693 189.312 222.693 191.036C222.693 192.76 224.091 194.158 225.815 194.158H228.951C228.971 194.158 228.99 194.159 229.01 194.159C229.03 194.159 229.049 194.158 229.069 194.158H232.654C234.378 194.158 235.776 192.76 235.776 191.036C235.776 189.312 234.378 187.914 232.654 187.914H232.168V187.505Z"
            fill="#768E11"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M222.723 190.588C222.939 192.102 224.24 193.265 225.813 193.265H228.949C228.969 193.266 228.989 193.266 229.008 193.266C229.028 193.266 229.048 193.266 229.067 193.265H232.652C234.225 193.265 235.527 192.102 235.742 190.588C235.763 190.733 235.774 190.881 235.774 191.032C235.774 192.757 234.376 194.154 232.652 194.154H229.067C229.048 194.155 229.028 194.155 229.008 194.155C228.989 194.155 228.969 194.155 228.949 194.154H225.813C224.089 194.154 222.691 192.757 222.691 191.032C222.691 190.881 222.702 190.733 222.723 190.588Z"
            fill="#6C7B17"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M223.146 187.505C223.146 185.761 221.733 184.347 219.989 184.347C218.245 184.347 216.831 185.761 216.831 187.505V187.914H216.794C215.07 187.914 213.672 189.312 213.672 191.036C213.672 192.76 215.07 194.158 216.794 194.158H219.93C219.949 194.158 219.969 194.159 219.989 194.159C220.008 194.159 220.028 194.158 220.048 194.158H223.632C225.357 194.158 226.754 192.76 226.754 191.036C226.754 189.312 225.357 187.914 223.632 187.914H223.146V187.505Z"
            fill="#9FCC76"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M240.287 187.505C240.287 185.761 238.873 184.347 237.129 184.347C235.385 184.347 233.972 185.761 233.972 187.505V187.914H233.934C232.21 187.914 230.812 189.312 230.812 191.036C230.812 192.76 232.21 194.158 233.934 194.158H237.07C237.09 194.158 237.11 194.159 237.129 194.159C237.149 194.159 237.169 194.158 237.188 194.158H240.773C242.497 194.158 243.895 192.76 243.895 191.036C243.895 189.312 242.497 187.914 240.773 187.914H240.287V187.505Z"
            fill="#88B01D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M213.74 190.364C214.046 191.767 215.295 192.817 216.79 192.817H219.926C219.945 192.818 219.965 192.818 219.985 192.818C220.004 192.818 220.024 192.818 220.044 192.817H223.628C225.123 192.817 226.373 191.767 226.679 190.364C226.726 190.579 226.75 190.803 226.75 191.032C226.75 192.756 225.353 194.154 223.628 194.154H220.044C220.024 194.154 220.004 194.154 219.985 194.154C219.965 194.154 219.945 194.154 219.926 194.154H216.79C215.066 194.154 213.668 192.756 213.668 191.032C213.668 190.803 213.693 190.579 213.74 190.364Z"
            fill="#95B96F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M230.886 190.366C231.192 191.769 232.442 192.819 233.936 192.819H237.072C237.092 192.82 237.112 192.82 237.131 192.82C237.151 192.82 237.171 192.82 237.19 192.819H240.775C242.27 192.819 243.519 191.769 243.825 190.366C243.872 190.581 243.897 190.805 243.897 191.034C243.897 192.758 242.499 194.156 240.775 194.156H237.19C237.171 194.156 237.151 194.156 237.131 194.156C237.112 194.156 237.092 194.156 237.072 194.156H233.936C232.212 194.156 230.814 192.758 230.814 191.034C230.814 190.805 230.839 190.581 230.886 190.366Z"
            fill="#809922"
          />
          <ellipse cx="214.573" cy="169.184" rx="10.8268" ry="10.7034" fill="#839F16" />
          <ellipse cx="214.571" cy="166.954" rx="9.47341" ry="8.47356" fill="#88B01D" />
          <ellipse cx="212.09" cy="164.948" rx="5.18782" ry="5.12874" fill="#A7C536" />
          <rect x="226.75" y="155.806" width="14.4357" height="24.0828" rx="7.21783" fill="#9FCC76" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M226.75 168.211V172.67C226.75 176.656 229.982 179.887 233.968 179.887C237.954 179.887 241.186 176.656 241.186 172.67V168.211C241.186 172.198 237.954 175.429 233.968 175.429C229.982 175.429 226.75 172.198 226.75 168.211Z"
            fill="#95B96F"
          />
          <rect x="228.559" y="160.265" width="4.06003" height="9.36552" rx="2.03002" fill="#AED288" />
          <rect x="87.9277" y="135.135" width="1.1391" height="57.4324" fill="#BB262E" />
          <rect x="152.855" y="135.135" width="2.2782" height="57.4324" fill="#BB262E" />
          <rect
            x="87.9277"
            y="135.135"
            width="1.12613"
            height="67.2069"
            transform="rotate(-90 87.9277 135.135)"
            fill="#BB262E"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3309_63815"
              x1="153.25"
              y1="131.908"
              x2="97.1214"
              y2="189.981"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#AFE2FF" />
              <stop offset="0.503687" stop-color="#C9EBFF" />
              <stop offset="1" stop-color="#93DBFF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    );
  }

  return (
    <div className={styles.svgContainer}>
      <svg width={width} height={height} viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M200.279 200.276C145.184 255.379 66.8565 266.364 25.2779 224.779C-16.2926 183.202 -5.30952 104.829 49.7773 49.7175C104.872 -5.38604 183.257 -16.3545 224.779 25.2143C266.349 66.7831 255.399 145.172 200.279 200.276Z"
          fill="#EEEEEE"
        />
        <rect x="5.99219" y="76.0938" width="231.907" height="2.28125" fill="#D8D8D8" />
        <rect x="7.14648" y="78.375" width="228.445" height="114.443" fill="#F2F2F2" />
        <path d="M54.0664 92.8223V133.314" stroke="#E3E3E3" strokeWidth="0.180363" />
        <path d="M80.2188 92.8223V133.314" stroke="#E3E3E3" strokeWidth="0.180363" />
        <path d="M106.369 92.8223V133.314" stroke="#E3E3E3" strokeWidth="0.180363" />
        <path d="M132.523 92.8223V133.314" stroke="#E3E3E3" strokeWidth="0.180363" />
        <path d="M158.67 92.8223V133.314" stroke="#E3E3E3" strokeWidth="0.180363" />
        <path d="M184.826 92.8223V133.314" stroke="#E3E3E3" strokeWidth="0.180363" />
        <path d="M210.977 92.8223V133.314" stroke="#E3E3E3" strokeWidth="0.180363" />
        <rect x="7.14648" y="78.375" width="228.445" height="14.4479" fill="#EAEAEA" />
        <path d="M34.0664 65.4492H207.131L203.285 112.595H37.9123L34.0664 65.4492Z" fill="#CB333B" />
        <rect x="33.2988" y="59.7441" width="174.988" height="5.70312" fill="#EBEBEB" />
        <rect x="32.1484" y="57.4639" width="176.141" height="2.28125" fill="#D8D8D8" />
        <rect x="9.06836" y="133.504" width="224.984" height="57.0312" fill="#CFCFCF" />
        <rect x="67.1426" y="123.144" width="106.496" height="8.76316" fill="#CB333B" />
        <rect x="84.6172" y="131.908" width="73.3376" height="56.8145" fill="url(#paint0_linear_1532_35287)" />
        <rect
          x="89.0977"
          y="123.144"
          width="65.5776"
          height="8.96182"
          transform="rotate(90 89.0977 123.144)"
          fill="#CB333B"
        />
        <rect
          x="162.438"
          y="123.144"
          width="65.5776"
          height="8.96182"
          transform="rotate(90 162.438 123.144)"
          fill="#CB333B"
        />
        <rect opacity="0.2" x="89.0977" y="131.908" width="64.3758" height="17.9645" fill="white" />
        <rect x="120.83" y="131.908" width="0.597455" height="56.8145" fill="#CDCDCD" />
        <rect x="89.1348" y="132.061" width="0.536384" height="56.6453" fill="#D9D9D9" />
        <rect
          x="153.502"
          y="131.798"
          width="0.524496"
          height="64.3661"
          transform="rotate(90 153.502 131.798)"
          fill="#D9D9D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.821 97.2688C178.623 102.467 171.234 103.504 167.311 99.5804C163.389 95.6581 164.425 88.2645 169.622 83.0653C174.82 77.8669 182.215 76.8321 186.132 80.7537C190.053 84.6753 189.02 92.0704 183.821 97.2688Z"
          fill="#45ADD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M171.635 84.8955H183.345C183.924 84.8955 184.396 85.3643 184.396 85.9472C184.396 86.5255 183.924 86.9951 183.345 86.9951H171.635C171.054 86.9951 170.585 86.5255 170.585 85.9472C170.585 85.3643 171.054 84.8955 171.635 84.8955ZM181.732 99.0597H171.266C170.81 99.0597 170.407 98.7675 170.265 98.3326C170.126 97.9008 170.278 97.4289 170.645 97.1591L173.858 94.8174C174.528 94.3324 175.525 94.7873 175.525 95.6663C175.525 95.9909 175.377 96.3109 175.094 96.5168C175.094 96.5168 174.675 96.8229 174.489 96.9578H180.301C177.226 94.5984 170.995 89.814 170.995 89.814C170.762 89.6321 170.585 89.3607 170.585 88.9412C170.585 88.4015 171.073 87.9304 171.588 87.9304H183.364C183.929 87.9304 184.396 88.4107 184.396 88.9798C184.396 89.3568 184.199 89.629 184.009 89.7878L180.926 92.3562C180.478 92.7286 179.819 92.6654 179.445 92.2182C179.282 92.0254 179.202 91.7856 179.202 91.5489C179.202 91.2466 179.332 90.949 179.581 90.7416C179.581 90.7416 180.093 90.3144 180.435 90.0299H174.727C177.51 92.1681 182.988 96.341 183.983 97.1043C183.624 97.5415 182.27 98.7628 181.732 99.0597Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.031 82.3505L181.825 81.1723C182.313 80.8508 182.971 80.9865 183.291 81.4777C183.613 81.9665 183.477 82.6227 182.988 82.945L181.192 84.1201C180.703 84.4424 180.046 84.3051 179.724 83.8178C179.402 83.3282 179.54 82.6705 180.031 82.3505Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.3946 94.6117V97.9226H98.9651C99.9534 97.9072 101.322 97.8663 101.322 96.2464C101.322 95.857 101.27 95.4661 100.931 95.1152C100.424 94.6033 99.709 94.6117 99.1424 94.6117H98.3946ZM96.522 93.4243H98.8895C99.7953 93.4336 101.164 93.4482 102.111 94.0882C102.878 94.6117 103.175 95.4028 103.175 96.1908C103.175 96.6774 103.078 97.0375 102.985 97.2757C102.299 99.0144 100.226 99.0445 99.4477 99.0538H96.522V93.4243Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.085 94.4797V96.004H107.62C107.884 95.9932 108.738 95.9793 108.738 95.2299C108.738 94.512 108.052 94.4889 107.665 94.4797H106.085ZM104.222 93.4287H107.886C109.826 93.4287 110.588 94.2715 110.588 95.2253C110.588 96.3595 109.568 96.6656 109.064 96.8082L110.778 99.0574H108.727L107.252 97.0488H106.085V99.0574H104.222V93.4287Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148.186 94.4797V96.004H149.726C149.985 95.9932 150.841 95.9793 150.841 95.2299C150.841 94.512 150.156 94.4889 149.766 94.4797H148.186ZM146.324 93.4287H149.989C151.928 93.4287 152.692 94.2715 152.692 95.2253C152.692 96.3595 151.673 96.6656 151.167 96.8082L152.882 99.0574H150.828L149.354 97.0488H148.186V99.0574H146.324V93.4287Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.894 93.4238V96.5659C117.885 97.1087 117.872 97.6507 117.461 98.1619C116.661 99.1327 115.01 99.1828 114.694 99.1828C114.136 99.1828 113.473 99.0787 112.966 98.8775C111.538 98.32 111.538 97.3315 111.546 96.5659V93.4238H113.42V96.9313C113.42 97.2212 113.429 97.4587 113.557 97.6361C113.79 97.9283 114.294 98.017 114.759 98.017C116.019 98.017 116.019 97.3631 116.032 96.9229V93.4238H117.894Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.43 98.528C124.127 98.7439 123.496 99.1834 122.246 99.1834C120.173 99.1834 118.899 97.9042 118.899 96.2465C118.899 94.4383 120.447 93.2324 122.559 93.2324C124.527 93.2324 125.48 94.1924 125.83 94.534L124.421 95.1786C124.157 94.8763 123.725 94.3805 122.698 94.3805C121.286 94.3805 120.75 95.2649 120.75 96.2071C120.75 96.8456 120.981 97.3637 121.34 97.6436C121.626 97.8741 122.129 98.0499 122.698 98.0499C123.031 98.0499 123.409 97.9852 123.716 97.834C124.201 97.5958 124.263 97.2912 124.313 96.9967H122.655V95.9288H125.885V99.0538H124.43V98.528Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.586 93.4238V99.0533H135.807L135.921 95.0415L135.941 94.4771L135.88 94.6737C135.783 94.9945 135.774 95.0353 135.688 95.2728L134.289 99.0533H132.689L131.344 95.4324L131.018 94.43C131.05 94.8773 131.05 94.9706 131.079 95.5041L131.176 99.0533H129.398V93.4238H132.09L133.227 96.5813L133.491 97.4826C133.593 97.0917 133.606 97.0524 133.687 96.7864L134.879 93.4238H137.586Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141.912 94.7629C141.819 95.0505 141.713 95.3473 141.609 95.6318C141.45 96.0567 141.167 96.7152 140.998 97.1392H142.883L141.912 94.7629ZM142.915 93.4243L145.653 99.053H143.672L143.302 98.1532H140.568L140.198 99.053H138.222L140.979 93.4243H142.915Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.93 93.4238H159.292V94.5418H157.046V99.0617H155.18V94.5418H152.93V93.4238Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.001 81.0137H72.0638V84.6176H75.9439V81.0137H80.046V90.9116H75.9439V87.1011H72.0638V90.9116H68.001V81.0137Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.4595 85.9634C85.4595 87.6882 86.37 88.7253 88.3027 88.7253C89.9432 88.7253 91.0919 87.7306 91.0919 85.867C91.0919 85.3882 91.0395 84.9548 90.856 84.5601C90.2354 83.1845 88.6704 83.1459 88.3027 83.1459C86.8449 83.1459 85.4595 83.917 85.4595 85.9634ZM95.1925 85.867C95.1925 88.3752 93.2775 91.2358 88.231 91.2358C82.9648 91.2358 81.3774 88.2349 81.3774 85.9772C81.3774 83.1459 83.6563 80.647 88.3027 80.647C93.3901 80.647 95.1925 83.5091 95.1925 85.867Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.6 83.0886V85.6107H102.935C103.155 85.5976 103.389 85.5976 103.609 85.5428C104.228 85.3894 104.594 84.9406 104.594 84.3377C104.594 83.1749 103.409 83.1156 102.898 83.0886H100.6ZM96.5205 81.0137H103.882C105.285 81.0538 106.509 81.0962 107.545 81.9227C108.494 82.6961 108.621 83.6067 108.621 84.239C108.621 85.064 108.403 85.9638 107.4 86.7626C106.325 87.6177 105.138 87.6609 103.735 87.6879H100.6V90.9116H96.5205V81.0137Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.84 83.0886V85.6107H116.173C116.394 85.5976 116.63 85.5976 116.848 85.5428C117.468 85.3894 117.834 84.9406 117.834 84.3377C117.834 83.1749 116.649 83.1156 116.139 83.0886H113.84ZM109.758 81.0137H117.12C118.525 81.0538 119.745 81.0962 120.783 81.9227C121.734 82.6961 121.861 83.6067 121.861 84.239C121.861 85.064 121.643 85.9638 120.64 86.7626C119.565 87.6177 118.38 87.6609 116.975 87.6879H113.84V90.9116H109.758V81.0137Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M122.992 81.0137H133.173V83.1857H127.074V84.66H132.771V86.8043H127.074V88.7681H133.72V90.9116H122.992V81.0137Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139.088 83.0747V85.3894H140.786C141.458 85.3894 142.753 85.3593 142.753 84.239C142.753 83.1024 141.473 83.0747 140.766 83.0747H139.088ZM135.007 81.0137H141.421C142.643 81.0137 143.808 81.0561 144.593 81.3074C146.307 81.8695 146.815 83.2173 146.815 84.1665C146.815 85.623 145.849 86.4619 144.173 86.9168L147.311 90.9124H142.789L140.291 87.4219H139.088V90.9124H135.007V81.0137Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.147 87.4901C150.906 88.5989 152.652 88.7947 153.825 88.7947C154.514 88.7947 155.671 88.6398 155.671 88.0514C155.671 87.743 155.438 87.5225 154.871 87.4307C154.443 87.3614 152.238 86.9951 151.606 86.8887C149.358 86.5124 148.104 85.4954 148.104 84.0003C148.104 81.9301 150.443 80.6895 153.79 80.6895C156.196 80.6895 158.337 81.2839 159.572 82.4444L157.018 84.0844C156.395 83.3619 154.992 83.0735 153.648 83.0735C152.953 83.0735 152.188 83.217 152.188 83.7166C152.188 84.197 152.85 84.2964 153.956 84.4769C156.809 84.9495 159.663 85.2479 159.663 87.7022C159.663 89.7154 157.522 91.2097 153.591 91.2097C150.932 91.2097 148.681 90.683 147.029 89.0353L150.147 87.4901Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.184 87.4901C57.9426 88.5989 59.688 88.7947 60.8598 88.7947C61.5491 88.7947 62.7039 88.6398 62.7039 88.0514C62.7039 87.743 62.4726 87.5225 61.9083 87.4307C61.4797 87.3614 59.2771 86.9951 58.6434 86.8887C56.3954 86.5124 55.1388 85.4954 55.1388 84.0003C55.1388 81.9301 57.4778 80.6895 60.8298 80.6895C63.2312 80.6895 65.3721 81.2839 66.6064 82.4444L64.0553 84.0844C63.4301 83.3619 62.027 83.0735 60.6848 83.0735C59.9902 83.0735 59.2232 83.217 59.2232 83.7166C59.2232 84.197 59.8846 84.2964 60.9932 84.4769C63.848 84.9495 66.6973 85.2479 66.6973 87.7022C66.6973 89.7154 64.5588 91.2097 60.6263 91.2097C57.9712 91.2097 55.7185 90.683 54.0664 89.0353L57.184 87.4901Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M186.337 99.5744L186.445 99.579C186.579 99.5828 186.686 99.5443 186.69 99.431C186.694 99.3407 186.631 99.269 186.476 99.2629C186.419 99.2605 186.381 99.2582 186.35 99.2605L186.337 99.5744ZM186.311 100.197L186.082 100.188L186.125 99.1048C186.214 99.094 186.309 99.0809 186.474 99.0878C186.66 99.0963 186.771 99.131 186.843 99.1888C186.902 99.2382 186.934 99.3145 186.93 99.4271C186.923 99.5589 186.828 99.6453 186.709 99.6715V99.68C186.805 99.6993 186.863 99.7903 186.873 99.9506C186.886 100.121 186.908 100.186 186.923 100.223L186.683 100.212C186.66 100.173 186.646 100.076 186.631 99.9283C186.619 99.8095 186.552 99.7594 186.433 99.7556L186.329 99.7509L186.311 100.197ZM186.532 98.8557C186.125 98.8411 185.779 99.1757 185.762 99.6106C185.745 100.047 186.061 100.404 186.471 100.423C186.882 100.441 187.23 100.108 187.248 99.6715C187.265 99.2374 186.947 98.8735 186.534 98.8557H186.532ZM186.547 98.6614C187.085 98.683 187.496 99.1395 187.474 99.6754C187.451 100.223 187.006 100.638 186.465 100.617C185.926 100.594 185.507 100.143 185.529 99.5952C185.55 99.0593 186.007 98.6399 186.545 98.6614H186.547Z"
          fill="#FFFFFE"
        />
        <rect
          x="11.3549"
          y="135.768"
          width="68.5044"
          height="52.8879"
          fill="#A5E1FF"
          stroke="white"
          strokeWidth="0.721451"
        />
        <path d="M12.584 187.499V136.848H27.565L12.584 187.499Z" fill="#BAE8FF" />
        <path d="M34.457 187.499V136.848H49.4381L34.457 187.499Z" fill="#BAE8FF" />
        <path d="M58.4238 187.499V136.848H73.4049L58.4238 187.499Z" fill="#BAE8FF" />
        <rect
          x="162.882"
          y="135.768"
          width="68.889"
          height="52.8879"
          fill="#A5E1FF"
          stroke="white"
          strokeWidth="0.721451"
        />
        <path d="M163.891 187.499V136.848H178.872L163.891 187.499Z" fill="#BAE8FF" />
        <path d="M186.062 187.499V136.848H201.044L186.062 187.499Z" fill="#BAE8FF" />
        <path d="M210.035 187.499V136.848H225.016L210.035 187.499Z" fill="#BAE8FF" />
        <rect x="10.9941" y="148.333" width="69.2258" height="1.52083" fill="white" />
        <rect
          x="34.4531"
          y="135.785"
          width="52.8489"
          height="1.53835"
          transform="rotate(90 34.4531 135.785)"
          fill="white"
        />
        <rect
          x="58.2949"
          y="135.785"
          width="52.8489"
          height="1.53835"
          transform="rotate(90 58.2949 135.785)"
          fill="white"
        />
        <rect
          x="185.98"
          y="135.785"
          width="52.8489"
          height="1.53835"
          transform="rotate(90 185.98 135.785)"
          fill="white"
        />
        <rect
          x="209.822"
          y="135.785"
          width="52.8489"
          height="1.53835"
          transform="rotate(90 209.822 135.785)"
          fill="white"
        />
        <rect x="162.521" y="148.332" width="69.2258" height="1.52083" fill="white" />
        <path d="M7.33789 112.785H235.399" stroke="#E3E3E3" strokeWidth="0.180363" />
        <path d="M27.9141 92.8223V133.314" stroke="#E3E3E3" strokeWidth="0.180363" />
        <path d="M7.05078 77.7031V190.316" stroke="#D8D8D8" strokeWidth="0.28103" />
        <rect x="9.92578" y="177.212" width="2.25557" height="12.0414" fill="#7E6B4E" />
        <rect x="28.8691" y="177.212" width="2.25557" height="12.0414" fill="#7E6B4E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6289 187.505C12.6289 185.761 11.2151 184.348 9.4711 184.348C7.7271 184.348 6.3133 185.761 6.3133 187.505V187.915H6.27614C4.55199 187.915 3.1543 189.313 3.1543 191.037C3.1543 192.761 4.55199 194.159 6.27613 194.159H9.41223C9.43181 194.159 9.45144 194.159 9.4711 194.159C9.49077 194.159 9.5104 194.159 9.52998 194.159H13.1148C14.8389 194.159 16.2366 192.761 16.2366 191.037C16.2366 189.313 14.8389 187.915 13.1148 187.915H12.6289V187.505Z"
          fill="#768E11"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.18588 190.59C3.40218 192.103 4.70333 193.266 6.27613 193.266H9.41223C9.43181 193.267 9.45144 193.267 9.4711 193.267C9.49077 193.267 9.5104 193.267 9.52998 193.266H13.1148C14.6876 193.266 15.9887 192.103 16.205 190.59C16.2259 190.736 16.2366 190.884 16.2366 191.036C16.2366 192.76 14.8389 194.158 13.1148 194.158H9.52998C9.5104 194.158 9.49077 194.158 9.4711 194.158C9.45144 194.158 9.43181 194.158 9.41223 194.158H6.27613C4.55199 194.158 3.1543 192.76 3.1543 191.036C3.1543 190.884 3.16507 190.736 3.18588 190.59Z"
          fill="#6C7B17"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4258 187.505C28.4258 185.761 27.012 184.348 25.268 184.348C23.524 184.348 22.1102 185.761 22.1102 187.505V187.915H22.073C20.3489 187.915 18.9512 189.313 18.9512 191.037C18.9512 192.761 20.3489 194.159 22.073 194.159H25.2091C25.2287 194.159 25.2483 194.159 25.268 194.159C25.2876 194.159 25.3073 194.159 25.3269 194.159H28.9117C30.6358 194.159 32.0335 192.761 32.0335 191.037C32.0335 189.313 30.6358 187.915 28.9117 187.915H28.4258V187.505Z"
          fill="#768E11"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9828 190.59C19.1991 192.103 20.5002 193.266 22.073 193.266H25.2091C25.2287 193.267 25.2483 193.267 25.268 193.267C25.2876 193.267 25.3073 193.267 25.3269 193.266H28.9117C30.4845 193.266 31.7856 192.103 32.0019 190.59C32.0227 190.736 32.0335 190.884 32.0335 191.036C32.0335 192.76 30.6358 194.158 28.9117 194.158H25.3269C25.3073 194.158 25.2876 194.158 25.268 194.158C25.2483 194.158 25.2287 194.158 25.2091 194.158H22.073C20.3489 194.158 18.9512 192.76 18.9512 191.036C18.9512 190.884 18.9619 190.736 18.9828 190.59Z"
          fill="#6C7B17"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3984 187.505C19.3984 185.761 17.9846 184.348 16.2406 184.348C14.4966 184.348 13.0828 185.761 13.0828 187.505V187.915H13.0457C11.3215 187.915 9.92383 189.313 9.92383 191.037C9.92383 192.761 11.3215 194.159 13.0457 194.159H16.1818C16.2013 194.159 16.221 194.159 16.2406 194.159C16.2603 194.159 16.2799 194.159 16.2995 194.159H19.8843C21.6085 194.159 23.0062 192.761 23.0062 191.037C23.0062 189.313 21.6085 187.915 19.8843 187.915H19.3984V187.505Z"
          fill="#9FCC76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.5391 187.505C36.5391 185.761 35.1253 184.348 33.3813 184.348C31.6373 184.348 30.2235 185.761 30.2235 187.505V187.915H30.1863C28.4621 187.915 27.0645 189.313 27.0645 191.037C27.0645 192.761 28.4621 194.159 30.1863 194.159H33.3224C33.342 194.159 33.3616 194.159 33.3813 194.159C33.4009 194.159 33.4206 194.159 33.4401 194.159H37.0249C38.7491 194.159 40.1468 192.761 40.1468 191.037C40.1468 189.313 38.7491 187.915 37.0249 187.915H36.5391V187.505Z"
          fill="#88B01D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.9897 190.363C10.2957 191.766 11.5451 192.817 13.0398 192.817H16.1759C16.1955 192.817 16.2151 192.817 16.2348 192.817C16.2544 192.817 16.2741 192.817 16.2937 192.817H19.8785C21.3732 192.817 22.6225 191.766 22.9286 190.363C22.9755 190.579 23.0003 190.802 23.0003 191.032C23.0003 192.756 21.6026 194.154 19.8785 194.154H16.2937C16.2741 194.154 16.2544 194.154 16.2348 194.154C16.2151 194.154 16.1955 194.154 16.1759 194.154H13.0398C11.3157 194.154 9.91797 192.756 9.91797 191.032C9.91797 190.802 9.94272 190.579 9.9897 190.363Z"
          fill="#95B96F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.1362 190.366C27.4422 191.769 28.6916 192.82 30.1863 192.82H33.3224C33.342 192.82 33.3616 192.82 33.3813 192.82C33.4009 192.82 33.4206 192.82 33.4401 192.82H37.0249C38.5197 192.82 39.769 191.769 40.0751 190.366C40.122 190.582 40.1468 190.805 40.1468 191.035C40.1468 192.759 38.7491 194.157 37.0249 194.157H33.4401C33.4206 194.157 33.4009 194.157 33.3813 194.157C33.3616 194.157 33.342 194.157 33.3224 194.157H30.1863C28.4621 194.157 27.0645 192.759 27.0645 191.035C27.0645 190.805 27.0892 190.582 27.1362 190.366Z"
          fill="#809922"
        />
        <ellipse cx="10.8268" cy="169.184" rx="10.8268" ry="10.7034" fill="#839F16" />
        <ellipse cx="10.825" cy="166.954" rx="9.47341" ry="8.47356" fill="#88B01D" />
        <ellipse cx="8.34407" cy="164.948" rx="5.18782" ry="5.12874" fill="#A7C536" />
        <rect x="23.0078" y="155.806" width="14.4357" height="24.0828" rx="7.21783" fill="#9FCC76" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.0078 168.213V172.671C23.0078 176.657 26.2393 179.888 30.2256 179.888C34.2119 179.888 37.4435 176.657 37.4435 172.671V168.213C37.4435 172.199 34.2119 175.43 30.2256 175.43C26.2393 175.43 23.0078 172.199 23.0078 168.213Z"
          fill="#95B96F"
        />
        <rect x="24.8125" y="160.265" width="4.06003" height="9.36552" rx="2.03002" fill="#AED288" />
        <path d="M236.01 77.7031V190.316" stroke="#D8D8D8" strokeWidth="0.28103" />
        <rect x="213.672" y="177.212" width="2.25557" height="12.0414" fill="#7E6B4E" />
        <rect x="232.615" y="177.212" width="2.25557" height="12.0414" fill="#7E6B4E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M216.379 187.505C216.379 185.761 214.965 184.348 213.221 184.348C211.477 184.348 210.063 185.761 210.063 187.505V187.915H210.026C208.302 187.915 206.904 189.313 206.904 191.037C206.904 192.761 208.302 194.159 210.026 194.159H213.162C213.182 194.159 213.201 194.159 213.221 194.159C213.241 194.159 213.26 194.159 213.28 194.159H216.865C218.589 194.159 219.987 192.761 219.987 191.037C219.987 189.313 218.589 187.915 216.865 187.915H216.379V187.505Z"
          fill="#768E11"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M206.936 190.59C207.152 192.103 208.453 193.266 210.026 193.266H213.162C213.182 193.267 213.201 193.267 213.221 193.267C213.241 193.267 213.26 193.267 213.28 193.266H216.865C218.438 193.266 219.739 192.103 219.955 190.59C219.976 190.736 219.987 190.884 219.987 191.036C219.987 192.76 218.589 194.158 216.865 194.158H213.28C213.26 194.158 213.241 194.158 213.221 194.158C213.201 194.158 213.182 194.158 213.162 194.158H210.026C208.302 194.158 206.904 192.76 206.904 191.036C206.904 190.884 206.915 190.736 206.936 190.59Z"
          fill="#6C7B17"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M232.168 187.505C232.168 185.761 230.754 184.348 229.01 184.348C227.266 184.348 225.852 185.761 225.852 187.505V187.915H225.815C224.091 187.915 222.693 189.313 222.693 191.037C222.693 192.761 224.091 194.159 225.815 194.159H228.951C228.971 194.159 228.99 194.159 229.01 194.159C229.03 194.159 229.049 194.159 229.069 194.159H232.654C234.378 194.159 235.776 192.761 235.776 191.037C235.776 189.313 234.378 187.915 232.654 187.915H232.168V187.505Z"
          fill="#768E11"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M222.723 190.588C222.939 192.102 224.24 193.265 225.813 193.265H228.949C228.969 193.266 228.989 193.266 229.008 193.266C229.028 193.266 229.048 193.266 229.067 193.265H232.652C234.225 193.265 235.526 192.102 235.742 190.588C235.763 190.733 235.774 190.882 235.774 191.033C235.774 192.757 234.376 194.155 232.652 194.155H229.067C229.048 194.155 229.028 194.155 229.008 194.155C228.989 194.155 228.969 194.155 228.949 194.155H225.813C224.089 194.155 222.691 192.757 222.691 191.033C222.691 190.882 222.702 190.733 222.723 190.588Z"
          fill="#6C7B17"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.146 187.505C223.146 185.761 221.733 184.348 219.989 184.348C218.245 184.348 216.831 185.761 216.831 187.505V187.915H216.794C215.07 187.915 213.672 189.313 213.672 191.037C213.672 192.761 215.07 194.159 216.794 194.159H219.93C219.949 194.159 219.969 194.159 219.989 194.159C220.008 194.159 220.028 194.159 220.048 194.159H223.632C225.357 194.159 226.754 192.761 226.754 191.037C226.754 189.313 225.357 187.915 223.632 187.915H223.146V187.505Z"
          fill="#9FCC76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.287 187.505C240.287 185.761 238.873 184.348 237.129 184.348C235.385 184.348 233.972 185.761 233.972 187.505V187.915H233.934C232.21 187.915 230.812 189.313 230.812 191.037C230.812 192.761 232.21 194.159 233.934 194.159H237.07C237.09 194.159 237.11 194.159 237.129 194.159C237.149 194.159 237.169 194.159 237.188 194.159H240.773C242.497 194.159 243.895 192.761 243.895 191.037C243.895 189.313 242.497 187.915 240.773 187.915H240.287V187.505Z"
          fill="#88B01D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.74 190.363C214.046 191.766 215.295 192.817 216.79 192.817H219.926C219.945 192.817 219.965 192.817 219.985 192.817C220.004 192.817 220.024 192.817 220.044 192.817H223.628C225.123 192.817 226.373 191.766 226.679 190.363C226.726 190.579 226.75 190.802 226.75 191.032C226.75 192.756 225.353 194.154 223.628 194.154H220.044C220.024 194.154 220.004 194.154 219.985 194.154C219.965 194.154 219.945 194.154 219.926 194.154H216.79C215.066 194.154 213.668 192.756 213.668 191.032C213.668 190.802 213.693 190.579 213.74 190.363Z"
          fill="#95B96F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.886 190.366C231.192 191.769 232.442 192.82 233.936 192.82H237.072C237.092 192.82 237.112 192.82 237.131 192.82C237.151 192.82 237.171 192.82 237.19 192.82H240.775C242.27 192.82 243.519 191.769 243.825 190.366C243.872 190.582 243.897 190.805 243.897 191.035C243.897 192.759 242.499 194.157 240.775 194.157H237.19C237.171 194.157 237.151 194.157 237.131 194.157C237.112 194.157 237.092 194.157 237.072 194.157H233.936C232.212 194.157 230.814 192.759 230.814 191.035C230.814 190.805 230.839 190.582 230.886 190.366Z"
          fill="#809922"
        />
        <ellipse cx="214.573" cy="169.184" rx="10.8268" ry="10.7034" fill="#839F16" />
        <ellipse cx="214.571" cy="166.954" rx="9.47341" ry="8.47356" fill="#88B01D" />
        <ellipse cx="212.09" cy="164.948" rx="5.18782" ry="5.12874" fill="#A7C536" />
        <rect x="226.75" y="155.806" width="14.4357" height="24.0828" rx="7.21783" fill="#9FCC76" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M226.75 168.212V172.67C226.75 176.656 229.982 179.887 233.968 179.887C237.954 179.887 241.186 176.656 241.186 172.67V168.212C241.186 172.198 237.954 175.429 233.968 175.429C229.982 175.429 226.75 172.198 226.75 168.212Z"
          fill="#95B96F"
        />
        <rect x="228.559" y="160.265" width="4.06003" height="9.36552" rx="2.03002" fill="#AED288" />
        <rect x="87.9277" y="135.135" width="1.1391" height="57.4324" fill="#BB262E" />
        <rect x="152.855" y="135.135" width="2.2782" height="57.4324" fill="#BB262E" />
        <rect
          x="87.9277"
          y="135.135"
          width="1.12613"
          height="67.2069"
          transform="rotate(-90 87.9277 135.135)"
          fill="#BB262E"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1532_35287"
            x1="153.25"
            y1="131.908"
            x2="97.1214"
            y2="189.981"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2FF" />
            <stop offset="0.503687" stopColor="#C9EBFF" />
            <stop offset="1" stopColor="#93DBFF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default SvgStoreLanding;
