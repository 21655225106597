import React from 'react';
import { Card, CardContent } from '@ldfeplatform/componentlibrary.ui.atoms.card';

import { IViewList } from './view-list.interface';
import SearchResultsContent from '@/components/search-results-content';

import styles from './view-list.module.scss';
import { TSearchResults } from '../store-locator/store-locator.interface';

const ViewList: React.FC<IViewList> = ({ storeLocatorResults }) => {
  return (
    <>
      {storeLocatorResults.map((results: TSearchResults, index: number) => (
        <Card key={index} className={styles.locationCard} variant="short">
          <CardContent>
            <SearchResultsContent index={index} searchResults={results} view="listView" />
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default ViewList;
