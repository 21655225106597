import { IPosition } from '../map/map.interface';
import { E_ActionType } from './constants';
import { TLayout, TSearchResults, IInitState, IActionType, TFilters } from './store-locator.interface';

export const searchResultsReducer = (state: IInitState, action: IActionType): IInitState => {
  switch (action.type) {
    case E_ActionType.loading:
      return { ...state, loading: true };
    case E_ActionType.error:
      return { ...state, loading: false, error: action.payload?.error };
    case E_ActionType.update: {
      const resultsPayload = getStoreLocatorData(action.payload.layout || null);
      return {
        ...state,
        mapPositions: resultsPayload.mapPositions || [],
        searchResults: resultsPayload.searchResults || [],
        filters: resultsPayload.filters || [],
        storeCount: resultsPayload.searchResults?.length ?? 0,
        hasResults: !!resultsPayload.searchResults?.length,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const instanceOfPosition = (data: any): data is IPosition[] => (data.length ? 'position' in data[0] : false);
const instanceOfSearchResults = (data: any): data is TSearchResults[] => (data.length ? 'name' in data[0] : false);
const instanceOfFilters = (data: any): data is TFilters => data.filterGroups !== undefined;
interface IInitialStateFn {
  (layout: TLayout | null): IInitState;
}

const getStoreLocatorData = (layout: TLayout | null) => {
  const mapPositions =
    (layout?.sections?.mainContentCollection?.components?.find(({ data }) => instanceOfPosition(data))
      ?.data as IPosition[]) || [];
  const searchResults =
    (layout?.sections?.mainContentCollection?.components?.find(({ data }) => instanceOfSearchResults(data))
      ?.data as TSearchResults[]) || [];
  const filters =
    (layout?.sections?.mainContentCollection?.components?.find(({ data }) => instanceOfFilters(data))
      ?.data as TFilters) || {};

  return {
    mapPositions,
    searchResults,
    filters,
  };
};
export const createInitialState: IInitialStateFn = (layout: TLayout | null): IInitState => {
  const storeLocatorData = getStoreLocatorData(layout);
  return {
    ...storeLocatorData,
    storeCount: storeLocatorData.searchResults.length,
    hasResults: !!storeLocatorData.searchResults.length,
    loading: false,
  };
};
