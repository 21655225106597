import React from 'react';
import { UnorderedList } from '@chakra-ui/react';
import type { AriaListBoxOptions } from '@react-aria/listbox';
// eslint-disable-next-line no-duplicate-imports
import { useListBox } from '@react-aria/listbox';

import { Option } from './option';

export type ListBoxProps = AriaListBoxOptions<unknown> & {
  listBoxRef: React.RefObject<HTMLUListElement>;
  state?: any;
};

export const ListBox = (props: ListBoxProps) => {
  const { listBoxRef, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <UnorderedList {...listBoxProps} data-testid="list-box" ref={listBoxRef} listStyleType="none" marginInlineStart={0}>
      {/* eslint-disable-next-line no-constant-condition */}
      {[...state.collection]
        ? [...state.collection].map((item) => <Option key={item.key} state={state} item={item} />)
        : null}
    </UnorderedList>
  );
};

ListBox.displayName = 'ListBox';
